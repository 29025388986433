import { LoadingButton } from '@mui/lab';

import { Document } from 'src/types/document';
import { ReactComponent as DownloadIcon } from 'src/assets/download.svg';
import useDownloadDocument from 'src/hooks/useDownloadDocument';

type Props = {
  product: Document;
};

const DownloadDocument = ({ product }: Props) => {
  const { mutate: downloadDocument, isLoading } = useDownloadDocument();

  return (
    <LoadingButton
      size="small"
      loading={isLoading}
      onClick={() =>
        downloadDocument({
          title: product.title,
          blockchainAddress: product.documentBlockchainAddress
        })
      }
    >
      <DownloadIcon style={{ visibility: isLoading ? 'hidden' : 'unset' }} />
    </LoadingButton>
  );
};

export default DownloadDocument;
