import { useEffect } from 'react';

import Text from 'src/components/text';
import { RelatedCertificateCard } from 'src/components/relatedCertificates';
import { Document } from 'src/types/document';
import useRelatedCertificates from 'src/hooks/useRelatedCertificates';
import Skeleton from 'src/components/skeleton';
import useSearchDocument from 'src/hooks/useSearchDocument';

import { StyledContent, StyledCertificates } from './styles';

type Props = {
  document?: Document;
  relatedField?: string;
};

const Certificates = ({ document, relatedField }: Props) => {
  const batteryCellBatchNumber = document?.details?.find(
    f => f.label === 'batteryCellsBatchNumber'
  );

  const { data } = useRelatedCertificates(relatedField as string, {
    enabled: Boolean(relatedField)
  });

  const { mutate, isLoading, data: searchDocumentData } = useSearchDocument();

  useEffect(() => {
    if (batteryCellBatchNumber) {
      mutate(batteryCellBatchNumber.value);
    }
  }, [mutate, batteryCellBatchNumber]);

  if (isLoading || (!searchDocumentData && !data)) {
    return <Skeleton height="258px" />;
  }

  return (
    <StyledContent>
      <Text variant="h5" hasBorder>
        Related certificates
      </Text>
      <StyledCertificates>
        {searchDocumentData?.map(searchDocument => (
          <RelatedCertificateCard
            key={searchDocument.documentBlockchainAddress}
            certificate={{
              category: searchDocument.category,
              productId: searchDocument.productId,
              blockchainAddress: searchDocument.documentBlockchainAddress,
              title: searchDocument.title
            }}
          />
        ))}
        {data
          ?.filter(f => f.category !== 'batteryPacks')
          .map(certificate => (
            <RelatedCertificateCard
              certificate={certificate}
              key={certificate.blockchainAddress}
            />
          ))}
      </StyledCertificates>
    </StyledContent>
  );
};

export default Certificates;
