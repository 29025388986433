import Text from 'src/components/text';
import { formatNumber } from 'src/utils/number';

import { ScoreIndicator, Container } from './styles';

type Props = {
  score?: string;
  maxScore: number;
  hideLabel?: boolean;
};

const Scoring = ({ score, maxScore, hideLabel }: Props) => {
  if (!score) {
    return null;
  }
  const value = Number(score);

  return (
    <Container>
      {!hideLabel && (
        <Text>
          {formatNumber(score)}/{maxScore}
        </Text>
      )}
      {Array.from(new Array(Number(maxScore))).map((_, index) => (
        <ScoreIndicator
          maxScore={maxScore}
          value={value - index - 1}
          key={`score_${index}`}
          score={Math.round(index + 1 <= value ? value : 0)}
        >
          <div />
        </ScoreIndicator>
      ))}
    </Container>
  );
};

export default Scoring;
