import { PropsWithChildren, useEffect } from 'react';

import AppBar from 'src/components/appbar';
import Footer from 'src/components/footer';

const Page = ({ title, children }: PropsWithChildren<{ title?: string }>) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppBar title={title} />
      {children}
      <Footer />
    </>
  );
};

export default Page;
