import { Document } from 'src/types/document';
import Card, { SuccessCard, ErrorCard } from 'src/components/card';
import Text from 'src/components/text';
import Skeleton from 'src/components/skeleton';
import { ReactComponent as ApprovedIcon } from 'src/assets/approved.svg';
import { ReactComponent as RejectedIcon } from 'src/assets/rejected.svg';
import { getByLabel } from 'src/utils/documentProperties';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';

type Props = {
  product?: Document;
};

const ReviewStatus = ({ product }: Props) => {
  if (!product) {
    return <Skeleton height="58px" />;
  }

  const reviewStatus = getByLabel(product.details, 'technicalReviewStatus');

  if (reviewStatus === 'Rejected') {
    return (
      <ErrorCard>
        <RejectedIcon />
        <Text>Rejected</Text>
      </ErrorCard>
    );
  }

  if (reviewStatus === 'Approved') {
    return (
      <SuccessCard>
        <ApprovedIcon />
        <Text>Approved</Text>
      </SuccessCard>
    );
  }

  return (
    <Card>
      <Text>{reviewStatus || DEFAULT_VALUE_TEXT}</Text>
    </Card>
  );
};

export default ReviewStatus;
