import { styled, Typography } from '@mui/material';

export const StyledDescription = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.custom.textColors[700]
}));

StyledDescription.defaultProps = {
  variant: 'body2'
};
