import Card from 'src/components/card';
import { Document } from 'src/types/document';
import Skeleton from 'src/components/skeleton';
import Label from 'src/components/label';
import { CardRow } from 'src/components/cardRow/styles';
import Scoring from 'src/components/scoring';
import { getByLabel } from 'src/utils/documentProperties';
import ScoringLabel from 'src/components/scoring/ScoringLabel';

import { CardContainer } from './styles';

type Props = {
  product?: Document;
};

const ProductScoringCard = ({ product }: Props) => {
  if (!product) {
    return <Skeleton height="367px" />;
  }

  return (
    <Card>
      <CardContainer>
        <Card shadow dense>
          <ScoringLabel label="Lithium Producer" category="producer" />
          <CardRow>
            <div>
              <Label>Child Labor Scoring</Label>
              <Scoring
                maxScore={6}
                score={getByLabel(
                  product.lithiumProducer,
                  'childLaborScoring(6Max,1Min)'
                )}
              />
            </div>
            <div>
              <Label>Human Rights Scoring</Label>
              <Scoring
                maxScore={6}
                score={getByLabel(
                  product.lithiumProducer,
                  'humanRightsScoring(6max,1Min)'
                )}
              />
            </div>
          </CardRow>
        </Card>
        <Card shadow dense>
          <ScoringLabel label="Cobalt Producer" category="producer" />
          <CardRow>
            <div>
              <Label>Child Labor Scoring</Label>
              <Scoring
                maxScore={6}
                score={getByLabel(
                  product.cobaltProducer,
                  'childLaborScoring(6Max,1Min)'
                )}
              />
            </div>
            <div>
              <Label>Human Rights Scoring</Label>
              <Scoring
                maxScore={6}
                score={getByLabel(
                  product.cobaltProducer,
                  'humanRightsScoring(6max,1Min)'
                )}
              />
            </div>
          </CardRow>
        </Card>
      </CardContainer>
    </Card>
  );
};

export default ProductScoringCard;
