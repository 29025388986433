import Page from 'src/components/page';
import BackToSearch from 'src/components/backToSearch';
import PageContainer from 'src/components/pageContainer';

import SearchPageContainer from './components/container';
import SearchResults from './components/searchResults';

const SearchPage = () => (
  <Page>
    <PageContainer>
      <BackToSearch />
      <SearchPageContainer>
        <SearchResults />
      </SearchPageContainer>
    </PageContainer>
  </Page>
);

export default SearchPage;
