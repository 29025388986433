import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import { Icon, Style } from 'ol/style';

import { DocumentProperties } from 'src/types/document';

type MaybeCoordinates = [number, number] | null;

export const extractCoordinatesFromProduct = (
  properties: DocumentProperties[],
  coordinatesLabel = 'coordinates'
): MaybeCoordinates => {
  const coordinatesRaw = properties.find(
    p => p.label === coordinatesLabel
  )?.value;

  if (!coordinatesRaw) {
    return null;
  }

  return coordinatesRaw
    .split(',')
    .map(t => Number(t.trim()))
    .reverse() as [number, number];
};

export const addPinToMap = (
  coordinates: MaybeCoordinates,
  pinSrc: string,
  { overridePinImage }: { overridePinImage?: string }
) => {
  if (!coordinates) {
    return null;
  }

  const iconFeature = new Feature({
    geometry: new Point(fromLonLat(coordinates))
  });

  const iconStyle = new Style({
    image: new Icon({
      size: [62, 74],
      src: overridePinImage || pinSrc,
      anchor: [0.5, 1]
    })
  });

  iconFeature.setStyle(iconStyle);

  return iconFeature;
};
