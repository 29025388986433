import { styled } from '@mui/material';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const TitleContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  svg: {
    width: '24px',
    height: '24px',
    color: theme.palette.custom.neutral[300]
  },
  h6: {
    flex: 1
  },
  button: {
    padding: theme.spacing(0, 1)
  },
  '.MuiIconButton-root': {
    borderRadius: theme.spacing(1)
  }
}));

export const ComponentPropertiesContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.custom.primary[100]}`
}));
