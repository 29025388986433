import Text from 'src/components/text';
import Label from 'src/components/label';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';

import { StyledContainer, StyledValue, StyledLabel, Caption } from './styles';

type Props = {
  label: string;
  value: string;
  caption?: string;
};

const BatteryProperty = ({ label, value, caption }: Props) => (
  <StyledContainer>
    <StyledLabel>
      <Label textAlign="center" display="block">
        {label.lastIndexOf(':') >= 0 ? label.replace(':', '') : label}
      </Label>
    </StyledLabel>
    <StyledValue>
      <Text textAlign="center" display={caption && 'inline'}>
        {value || DEFAULT_VALUE_TEXT}
      </Text>
      {caption && (
        <Text textAlign="center" display="inline">
          <Caption>{caption}</Caption>
        </Text>
      )}
    </StyledValue>
  </StyledContainer>
);

export default BatteryProperty;
