import { useParams } from 'react-router-dom';

import Page from 'src/components/page';
import Text from 'src/components/text';
import PageHeader from 'src/components/pageHeader';
import useDocument from 'src/hooks/useDocument';
import CompositionGrid from 'src/components/compositionGrid';
import CompositionRow from 'src/components/compositionRow';
import BackToSearch from 'src/components/backToSearch';
import HeaderContainer from 'src/components/headerContainer';
import PageContainer from 'src/components/pageContainer';
import ContentContainer from 'src/components/pageContentContainer';
import CardContainer from 'src/components/cardContainer';
import Properties, { PropertiesLayout } from 'src/components/properties';
import ChemistryProducersMap from 'src/components/chemistryProducersMap';
import DocumentCategory from 'src/components/documentCategory';

import ProductBatchCard from './components/productBatchCard';
import ProductScoringCard from './components/productScoringCard';
import ProductDetailsCard from './components/productDetailsCard';
import PropertyColumn from './components/propertyColumn';
import ElectricalPerformance from './components/electricalPerformance';

const Chemistry = () => {
  const { productId } = useParams();
  const { data: product } = useDocument(productId as string, {
    enabled: Boolean(productId)
  });

  return (
    <Page title="Battery Product Passport">
      <PageContainer>
        <BackToSearch />
        <HeaderContainer>
          <PageHeader>Battery Cell Chemistry Certificate</PageHeader>
          <DocumentCategory document={product} />
        </HeaderContainer>
        <ContentContainer>
          <div>
            <CardContainer>
              <ProductBatchCard product={product} />
              <ProductDetailsCard product={product} />
            </CardContainer>
          </div>
          <div>
            <Text variant="h5" dense>
              Scoring
            </Text>
            <ProductScoringCard product={product} />
          </div>
          <div>
            <Text variant="h5" hasBorder>
              Specification
            </Text>
            <PropertiesLayout>
              <Properties>
                <Text color="label">Chemical composition</Text>
                <CompositionGrid composition={product?.chemicalComposition} />
              </Properties>
              <PropertyColumn>
                <Properties>
                  <Text color="label">Physical Properties</Text>
                  <CompositionRow composition={product?.physicalProperties} />
                </Properties>
                <Properties>
                  <Text color="label">Particle Size Distribution (PSD) µm</Text>
                  <CompositionRow
                    composition={product?.particleSizeDistribution}
                  />
                </Properties>
                <Properties>
                  <Text color="label">
                    Electrochemical Performance - Specification
                  </Text>
                  <ElectricalPerformance
                    text={product?.electrochemicalPerformance}
                  />
                </Properties>
              </PropertyColumn>
            </PropertiesLayout>
          </div>
          <div>
            <ChemistryProducersMap
              title="Chemistry producers"
              product={product}
            />
          </div>
        </ContentContainer>
      </PageContainer>
    </Page>
  );
};

export default Chemistry;
