import { useParams } from 'react-router-dom';

import Page from 'src/components/page';
import useDocument from 'src/hooks/useDocument';
import BackToSearch from 'src/components/backToSearch';
import HeaderContainer from 'src/components/headerContainer';
import PageHeader from 'src/components/pageHeader';
import PageContainer from 'src/components/pageContainer';
import ContentContainer from 'src/components/pageContentContainer';
import CardContainer from 'src/components/cardContainer';
import Text from 'src/components/text';
import Properties, { PropertiesLayout } from 'src/components/properties';
import { Column } from 'src/components/flex';
import Documents from 'src/components/documents/DocumentCertificates';
import DocumentCategory from 'src/components/documentCategory';
import CardRow from 'src/components/cardRow';
import Label from 'src/components/label';
import { formatByLabel, getByLabel } from 'src/utils/documentProperties';
import Progress from 'src/components/progress';
import { formatToProgressValue } from 'src/components/progress/utils';
import CompositionRow from 'src/components/compositionRow';

import ProductCard from './components/productCard';
import ProducersMap from './components/producersMap';
import DetailsCard from './components/detailsCard';

const BatteryRecycle = () => {
  const { productId } = useParams();
  const { data: product } = useDocument(productId as string, {
    enabled: Boolean(productId)
  });

  return (
    <Page title="Battery Product Passport">
      <PageContainer>
        <BackToSearch />
        <HeaderContainer>
          <PageHeader>Battery Pack Recycling Certificate</PageHeader>
          <DocumentCategory document={product} />
        </HeaderContainer>
        <ContentContainer>
          <div>
            <CardContainer>
              <ProductCard product={product} />
              <DetailsCard product={product} />
            </CardContainer>
          </div>
          <CardContainer>
            <div style={{ flex: 1 }}>
              <Text variant="h5" hasBorder>
                Recycling description
              </Text>
              <CardRow>
                <div>
                  <Label>Recycle data</Label>
                  <Text>
                    {formatByLabel(product?.details, 'recycleData', {
                      dateLabels: ['recycleData']
                    })}
                  </Text>
                </div>
                <div>
                  <Label>Recycle percent</Label>
                  <CardRow dense>
                    <Text>
                      {formatByLabel(product?.details, 'recyclePercent', {
                        percentLabels: ['recyclePercent']
                      })}
                    </Text>
                    <Progress
                      value={formatToProgressValue(
                        getByLabel(product?.details, 'recyclePercent')
                      )}
                    />
                  </CardRow>
                </div>
                <div>
                  <Label>Recycle material</Label>
                  <Text>{getByLabel(product?.details, 'recycleMaterial')}</Text>
                </div>
              </CardRow>
            </div>
            <Documents
              document={product}
              relatedFields={[
                'batteryCellsBatchNumber',
                'batteryPackSerialNumber'
              ]}
            />
          </CardContainer>
          <div>
            <Text variant="h5" hasBorder>
              Specification
            </Text>
            <Column>
              <PropertiesLayout>
                <Properties>
                  <Text color="label">General info</Text>
                  <CompositionRow
                    labels={[
                      'Cells setup',
                      'Weight',
                      'Gravimetric Energy Density:'
                    ]}
                    composition={product?.details}
                  />
                </Properties>
              </PropertiesLayout>
            </Column>
          </div>
          <div>
            <ProducersMap title="Recycling company" product={product} />
          </div>
        </ContentContainer>
      </PageContainer>
    </Page>
  );
};

export default BatteryRecycle;
