import { StyledCard } from './styles';
import SuccessCardComponent from './SuccessCard';
import ErrorCardComponent from './ErrorCard';
import RankCardComponent from './RankCard';

export const SuccessCard = SuccessCardComponent;
export const ErrorCard = ErrorCardComponent;
export const RankCard = RankCardComponent;

export default StyledCard;
