import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Document } from 'src/types/document';
import searchDocuments from 'src/api/searchDocumentsByProductId';
import { queryKeys } from 'src/constants/queryKeys';

const useSearchDocument = (
  options?: UseMutationOptions<Document[], AxiosError, string>
) => useMutation<Document[], AxiosError, string>(searchDocuments, options);

export const useGetDocument = (
  productId: string,
  options?: UseQueryOptions<Document[], AxiosError>
) =>
  useQuery<Document[], AxiosError>(
    [queryKeys.getDocumentsByProductId, { productId }],
    () => searchDocuments(productId),
    options
  );

export default useSearchDocument;
