import { DEFAULT_VALUE_TEXT } from 'src/constants/text';

export const parseDate = (date?: string, defaultValue = '-') => {
  if (!date) {
    return defaultValue;
  }
  if (date === DEFAULT_VALUE_TEXT) {
    return date;
  }

  let parsedDate = Date.parse(date);

  if (isNaN(parsedDate)) {
    parsedDate = Date.parse(date.replaceAll('.', '/'));
    if (isNaN(parsedDate)) {
      return date;
    }
  }

  return new Intl.DateTimeFormat(navigator.language).format(parsedDate);
};
