import Text from 'src/components/text';
import Card from 'src/components/card';
import { Document } from 'src/types/document';
import Skeleton from 'src/components/skeleton';
import Label from 'src/components/label';
import { getByLabel } from 'src/utils/documentProperties';
import Map, { MapContainer } from 'src/components/map';
import { Pin } from 'src/types/map';
import { extractCoordinatesFromProduct } from 'src/components/map/utils';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';

import { CardContent } from './styles';

type Props = {
  title: string;
  product?: Document;
};

const ProducersMap = ({ product, title }: Props) => {
  if (!product) {
    return (
      <MapContainer>
        <Text variant="h5" dense>
          {title}
        </Text>
        <Skeleton height="700px" />
      </MapContainer>
    );
  }

  const pins: Pin[] = [];

  if (product.producer) {
    const coordinates = extractCoordinatesFromProduct(product.producer);

    if (coordinates) {
      pins.push({
        coordinates,
        iconPath: '/pin-orange.svg'
      });
    }
  }

  return (
    <MapContainer>
      <Text variant="h5" dense>
        {title}
      </Text>
      <Map
        pins={pins}
        card={
          <Card shadow dense style={{ opacity: 0.85 }}>
            <CardContent>
              <div>
                <Text hasBorder color="primary">
                  Producer
                </Text>
                {getByLabel(product.producer, 'manufacturer') && (
                  <div>
                    <Label>Company</Label>
                    <Text>{getByLabel(product.producer, 'manufacturer')}</Text>
                  </div>
                )}
                <div>
                  <Label>Company Full name</Label>
                  <Text>{getByLabel(product.producer, 'companyFullName')}</Text>
                </div>
                <div>
                  <Label>Company Headquarters</Label>
                  <Text>
                    {getByLabel(
                      product.producer,
                      'companyHeadquaters',
                      DEFAULT_VALUE_TEXT
                    )}
                  </Text>
                </div>
                <div>
                  <Label>Factory Location</Label>
                  <Text>
                    {getByLabel(product.producer, 'factoryLocation', '-')}
                  </Text>
                </div>
                <div>
                  <Label>Chemistry batch number</Label>
                  <Text>{product.productId}</Text>
                </div>
                <div>
                  <Label>Taric code</Label>
                  <Text>
                    {getByLabel(
                      product.details,
                      'taricCode',
                      DEFAULT_VALUE_TEXT
                    )}
                  </Text>
                </div>
              </div>
            </CardContent>
          </Card>
        }
      />
    </MapContainer>
  );
};

export default ProducersMap;
