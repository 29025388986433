import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/constants/api';
import { Document, SearchDocumentResponse } from 'src/types/document';

const searchDocuments = async (productId: string): Promise<Document[]> => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  const response = await apiClient.get<SearchDocumentResponse[]>(
    apiRoutes.documentByProductId.replace('{productId}', encodeURI(productId))
  );

  return response.data.map(r => ({
    documentBlockchainAddress: r.document.blockchainAddress,
    publisherId: '',
    category: r.category,
    title: r.title,
    ...r.additionalDetailsObject
  }));
};

export default searchDocuments;
