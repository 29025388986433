import { DocumentCategories } from 'src/types/categories';
import routes from 'src/constants/routes';

export const resolveProductUrl = (category: DocumentCategories): string => {
  switch (category.toLowerCase()) {
    case 'batterycells':
      return routes.productBatteryCellsId;
    case 'chemistry':
      return routes.productChemistryId;
    case 'batterypacks':
      return routes.productBatteryPacksId;
    case 'batteriestechreview':
      return routes.productTechReviewId;
    case 'batterypacksrepair':
      return routes.productBatteryPackRepairsId;
    case 'batterypacksrecycling':
      return routes.productBatteryRecyclingId;
    case 'batterywaste':
      return routes.productBatteryWasteId;
    case 'batteryrepurpose':
      return routes.productBatteryRepurposeId;
    case 'certificate':
      return routes.certificateId;
    default:
      throw new Error('Cannot resolve product url id');
  }
};
