import { styled } from '@mui/material';

export const StyledCard = styled('div', {
  shouldForwardProp: propName =>
    !['dense', 'shadow'].includes(propName as string)
})<{ dense?: boolean; shadow?: boolean }>(({ theme, dense, shadow }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(dense ? 2 : 4),
  gap: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.custom.textColors[50],
  border: `1px solid ${theme.palette.custom.primary[100]}`,
  boxShadow: shadow ? '0px 2px 4px 0px #D6DDEF' : 'unset'
}));

export const CardWithStatusContainer = styled('div')(({ theme }) => ({
  gap: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center'
}));

export const StyledSuccessCard = styled(StyledCard)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.custom.success[50],
  border: `1px solid ${theme.palette.custom.success[400]}`,
  flex: 1,
  span: {
    color: theme.palette.custom.success[600]
  }
}));

export const StyledErrorCard = styled(StyledCard)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.custom.error[50],
  border: `1px solid ${theme.palette.custom.error[700]}`,
  flex: 1
}));

export const StyledRankFairCard = styled(StyledCard)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.custom.warning[50],
  border: `1px solid ${theme.palette.custom.warning[600]}`,
  flex: 1
}));

export const StyledRankGoodCard = styled(StyledCard)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.custom.success[50],
  border: `1px solid ${theme.palette.custom.success[400]}`,
  flex: 1
}));

export const StyledVeryGoodCard = styled(StyledCard)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.custom.success[50],
  border: `1px solid ${theme.palette.custom.success[600]}`,
  flex: 1
}));
