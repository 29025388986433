import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import { Feature, Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';

import { Pin } from 'src/types/map';

import { StyledContainer } from './styles';
import { addPinToMap } from './utils';

const viewCenter = [37.41, 8.82];
type Props = {
  pins: Pin[];
  card?: ReactNode;
};

const MapComponent = ({ card, pins }: Props) => {
  const mapRef = useRef<Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [cardCssProperties, setCardCssProperties] = useState<CSSProperties>({
    position: 'absolute',
    zIndex: 1,
    display: 'none'
  });
  useEffect(() => {
    if (!mapContainerRef.current || mapRef.current || !card) {
      return;
    }

    const features = pins.map(p =>
      addPinToMap(p.coordinates, p.iconPath, {
        overridePinImage: pins.length === 1 ? '/pin-blue.svg' : undefined
      })
    );

    const vectorSource = new VectorSource({
      features: features.filter(Boolean) as Feature[]
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource
    });

    const layer = new TileLayer({
      source: new OSM({})
    });

    mapRef.current = new Map({
      controls: [],
      target: mapContainerRef.current,
      interactions: [],
      layers: [layer, vectorLayer],
      view: new View({
        center: fromLonLat(viewCenter),
        zoom: 1
      })
    });

    mapRef.current.on('postrender', () => {
      if (features && mapContainerRef.current) {
        const containerBox = mapContainerRef.current.getBoundingClientRect();
        const featuresPixelCoordinates = features
          .map(f => {
            const g = f?.getGeometry() as Point;
            if (g) {
              return mapRef.current?.getPixelFromCoordinate(
                g.getCoordinates()
              )[0];
            }

            return null;
          })
          .filter(Boolean) as number[];
        const hasDifferentLocation =
          featuresPixelCoordinates.length === 2 &&
          featuresPixelCoordinates[0] !== featuresPixelCoordinates[1];

        // little hacky but map is not highest prio can be update to calculate this props dynamically
        const CARD_HEIGHT = 300;
        const left = hasDifferentLocation
          ? Math.abs(
              featuresPixelCoordinates[0] - featuresPixelCoordinates[1]
            ) / 2
          : containerBox.width / 2;

        setCardCssProperties({
          ...cardCssProperties,
          left,
          top: containerBox.height / 2 - CARD_HEIGHT,
          display: 'block'
        });
      }
    });
  }, [pins, cardCssProperties, card]);

  if (!pins) {
    return null;
  }

  return (
    <StyledContainer ref={mapContainerRef}>
      <div style={cardCssProperties}>{card}</div>
    </StyledContainer>
  );
};

export default MapComponent;
