import Text from 'src/components/text';
import RelatedCertificates from 'src/components/relatedCertificates';
import { Document } from 'src/types/document';

import { StyledContent } from './styles';

type Props = {
  document?: Document;
  relatedFields: string[];
};

const DocumentCertificates = ({ document, relatedFields }: Props) => {
  const relatedProductIds = [
    ...(document?.details?.filter(f => relatedFields.includes(f.label)) || [])
  ];

  return (
    <StyledContent>
      <Text variant="h5" hasBorder>
        Related certificates
      </Text>
      {relatedProductIds?.map(p => (
        <RelatedCertificates productId={p.value} key={p.value} />
      ))}
    </StyledContent>
  );
};

export default DocumentCertificates;
