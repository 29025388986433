import { useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { Document } from 'src/types/document';
import Skeleton from 'src/components/skeleton';
import { categoryIconMapping } from 'src/utils/category';

import { StyledRoot } from './styles';

type Props = {
  children?: ReactNode;
  document?: Document;
};

const DocumentCategory = ({ document, children }: Props) => {
  const theme = useTheme();

  if (!document) {
    return <Skeleton height="60" />;
  }

  return (
    <StyledRoot>
      {children}
      {categoryIconMapping
        .filter(f => f.name)
        .map(({ Icon, category }) => (
          <Icon
            key={category}
            className={
              document.category.toLowerCase() === category
                ? 'Active'
                : undefined
            }
            color={
              document.category.toLowerCase() === category
                ? theme.palette.primary.main
                : undefined
            }
          />
        ))}
    </StyledRoot>
  );
};

export default DocumentCategory;
