import { styled, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.neutral[900],
  fontWeight: '600'
}));

StyledTypography.defaultProps = {
  variant: 'h3'
};
