import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Document } from 'src/types/document';
import getDocument from 'src/api/getDocument';
import { queryKeys } from 'src/constants/queryKeys';

const useDocument = (
  productId: string,
  options?: UseQueryOptions<Document, AxiosError>
) =>
  useQuery<Document, AxiosError>(
    [queryKeys.getDocument, { productId }],
    () => getDocument(productId),
    options
  );

export default useDocument;
