import { Components, Theme } from '@mui/material';

import { MuiAppBarComponentConfiguration } from './defaultComponents/MuiAppBar';
import { MuiInputAdornmentComponentConfiguration } from './defaultComponents/MuiInputAdornment';
import { MuiOutlinedInputComponentConfiguration } from './defaultComponents/MuiOutlinedInput';
import { MuiInputLabelComponentConfiguration } from './defaultComponents/MuiInputLabel';
import { MuiButtonComponentConfiguration } from './defaultComponents/MuiButton';

const components: Components<Theme> = {
  MuiAppBar: MuiAppBarComponentConfiguration,
  MuiButton: MuiButtonComponentConfiguration,
  MuiInputAdornment: MuiInputAdornmentComponentConfiguration,
  MuiOutlinedInput: MuiOutlinedInputComponentConfiguration,
  MuiInputLabel: MuiInputLabelComponentConfiguration
};

export default components;
