import { styled } from '@mui/material';

export const StyledContent = styled('div')(() => ({
  flex: 1
}));

export const StyledCertificates = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(1),
  display: 'grid',
  gridTemplateColumns: '1fr 1fr'
}));
