import Text from 'src/components/text';
import Card from 'src/components/card';
import { Document } from 'src/types/document';
import Skeleton from 'src/components/skeleton';
import CardRow from 'src/components/cardRow';
import Label from 'src/components/label';
import { formatByLabel, getByLabel } from 'src/utils/documentProperties';

type Props = {
  product?: Document;
};

const DetailsCard = ({ product }: Props) => {
  if (!product) {
    return <Skeleton height="270px" />;
  }

  if (!product.details) {
    return null;
  }

  return (
    <Card>
      <CardRow wrap>
        <div>
          <Label>EV Model</Label>
          <Text>{getByLabel(product.details, 'eVModel')}</Text>
        </div>
        <div>
          <Label>Battery Producers</Label>
          <Text>
            <Text>{getByLabel(product.details, 'batteryProducers')}</Text>
          </Text>
        </div>
        <div>
          <Label>Battery Production Date</Label>
          <Text>
            {formatByLabel(product.details, 'batteryProductionDate', {
              dateLabels: ['batteryProductionDate']
            })}
          </Text>
        </div>
        <div>
          <Label>Battery Cell Model</Label>
          <Text>
            <Text>{getByLabel(product.details, 'batteryCellModel')}</Text>
          </Text>
        </div>
        <div>
          <Label>Battery Packs Global Trade Identification Number</Label>
          <Text>
            {Number(
              getByLabel(
                product.details,
                'batteryPacksGlobalTradeIdentificationNumber'
              )
            )}
          </Text>
        </div>
        <div>
          <Label>Battery Pack Recycling Certificate UID</Label>
          <Text>{product.productId}</Text>
        </div>
      </CardRow>
    </Card>
  );
};

export default DetailsCard;
