import { styled } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: theme.spacing(100),
  '.ol-attribution': {
    bottom: 0,
    right: 0,
    background: 'rgba(255, 255, 255, 0.75)',
    position: 'absolute',
    lineHeight: 'normal',
    display: 'flex',
    maxWidth: 'calc(100% - 3em)',
    ul: {
      margin: 0,
      padding: '1px 0.5em',
      color: '#333333',
      textShadow: '0 0 2px #fff',
      fontSize: '12px',
      li: {
        listStyle: 'none'
      }
    },
    button: {
      display: 'none'
    }
  }
}));

export const StyledMapContainer = styled('div')(() => ({
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
}));
