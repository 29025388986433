import { styled, Typography } from '@mui/material';
import { Palette } from '@mui/material/styles/createPalette';

const resolveColor = (
  palette: Palette,
  color?: 'primary' | 'label' | 'secondary'
) => {
  switch (color) {
    case 'label':
      return palette.custom.textColors[900];
    case 'secondary':
      return palette.custom.warning[500];
    case 'primary':
      return palette.primary.main;

    default:
      return palette.custom.neutral[900];
  }
};

export const StyledText = styled(Typography, {
  shouldForwardProp: prop =>
    !['wordBreak', 'color', 'hasBorder', 'dense'].includes(prop.toString())
})<{
  color?: 'primary' | 'label' | 'secondary';
  wordBreak?: boolean;
  hasBorder?: boolean;
  dense?: boolean;
}>(({ theme, color, variant, dense, wordBreak, hasBorder }) => ({
  color: resolveColor(theme.palette, color),
  whiteSpace: 'break-spaces',
  fontWeight: 600,
  wordBreak: wordBreak ? 'break-all' : 'unset',
  marginBottom: variant === 'h5' ? theme.spacing(3) : 'unset',
  paddingBottom:
    variant === 'h5' || hasBorder
      ? dense
        ? 'unset'
        : theme.spacing(1)
      : 'unset',
  borderBottom: hasBorder
    ? `1px solid ${theme.palette.custom.primary[100]}`
    : 'unset'
}));

StyledText.defaultProps = {
  variant: 'h6s'
};
