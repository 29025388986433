import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2)
}));

export const CardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(10),
  '> div': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}));
