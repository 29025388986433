import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/constants/api';
import { GetRelatedCertificatesResponse } from 'src/types/document';

const getRelatedCertificates = async (
  documentId: string
): Promise<GetRelatedCertificatesResponse> => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  const response = await apiClient.get<GetRelatedCertificatesResponse>(
    apiRoutes.documentRelatedCertificatesId.replace(
      '{documentId}',
      encodeURI(documentId)
    )
  );

  return response.data;
};

export default getRelatedCertificates;
