import { Button } from '@mui/material';

import Label from 'src/components/label';
import Text from 'src/components/text';
import { formatBlockchainAddress } from 'src/utils/blockchain';
import { ReactComponent as CopySvg } from 'src/assets/copy.svg';

import { Container, ValueContainer } from './styles';

type Props = {
  blockchainAddress?: string;
};

const copy = (address?: string) => {
  if (!address) {
    return;
  }

  navigator.clipboard.writeText(address);
};

const BlockchainAddress = ({ blockchainAddress }: Props) => (
  <Container>
    <Label>Blockchain address</Label>
    <ValueContainer>
      <Text>{formatBlockchainAddress(blockchainAddress)}</Text>
      <Button onClick={() => copy(blockchainAddress)} startIcon={<CopySvg />}>
        <Text variant="caption1">Copy</Text>
      </Button>
    </ValueContainer>
  </Container>
);

export default BlockchainAddress;
