import Label from 'src/components/label';
import Scoring from 'src/components//scoring';
import { getByLabel } from 'src/utils/documentProperties';
import Card from 'src/components/card';
import Text from 'src/components/text';
import { Document } from 'src/types/document';
import ScoringLabel from 'src/components/scoring/ScoringLabel';

type Props = {
  product?: Document;
};

const BatteryCellsScore = ({ product }: Props) => {
  if (!product) {
    return null;
  }

  return (
    <Card shadow dense>
      <ScoringLabel label="Battery Cells Score" category="chemistry" />
      <div>
        <Label>Cells batch number</Label>
        <Text>{getByLabel(product.details, 'batteryCellsBatchNumber')}</Text>
      </div>
      <div>
        <Label>Battery Cells Child Labor Average</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.details, 'batteryCellsChildLaborAverage')}
        />
      </div>
      <div>
        <Label>Battery Cells Human Rights Average</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.details, 'batteryCellsHumanRightsAverage')}
        />
      </div>
      <div>
        <Label>Battery Cell Producer ESG Scoring</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.details, 'batteryCellProducerEsgScoring')}
        />
      </div>
    </Card>
  );
};
export default BatteryCellsScore;
