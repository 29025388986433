import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { RelatedCertificate } from 'src/types/document';
import Card from 'src/components/card';
import Text from 'src/components/text';
import { getIconByCategory } from 'src/utils/category';
import { ReactComponent as GoToIcon } from 'src/assets/goto.svg';
import { resolveProductUrl } from 'src/utils/url';

import {
  StyledCardTitle,
  StyledCardTitleDescription,
  StyledIconContainer
} from './styles';

type Props = {
  certificate: RelatedCertificate;
};

const resolveCertificateTitleByCategory = (category: string) => {
  switch (category) {
    case 'batterycells':
      return 'Battery Cell Certificate';
    case 'batteriestechreview':
      return 'Battery Pack Technical Review Certificate';
    case 'batterypacksrepair':
      return 'Battery Pack Repair Certificate';
    case 'batterypacksrecycling':
      return 'Battery Pack Recycling Certificate';
    case 'batterywaste':
      return 'Battery Pack Waste Certificate';
    case 'batteryrepurpose':
      return 'Battery Pack Repurpose Certificate';
    default:
      return null;
  }
};

const resolveCertificateIdByCategory = (category: string) => {
  switch (category) {
    case 'batterycells':
      return 'Battery Cells Batch No';
    case 'batteriestechreview':
      return 'Battery Pack Technical Review Certificate ID';
    case 'batterypacksrepair':
      return 'Battery Pack Repair Certificate ID';
    case 'batterypacksrecycling':
      return 'Battery Pack Recycling Certificate ID';
    case 'batterywaste':
      return 'Battery Pack Waste Certificate ID';
    case 'batteryrepurpose':
      return 'Battery Pack Repurpose Certificate ID';

    default:
      return null;
  }
};

const RelatedCertificateCard = ({ certificate }: Props) => {
  const Icon = getIconByCategory(certificate.category.toLowerCase())?.Icon;
  const url = resolveProductUrl(certificate.category).replace(
    ':productId',
    encodeURI(certificate.blockchainAddress)
  );

  return (
    <Card dense>
      <StyledCardTitle>
        {Icon && (
          <StyledIconContainer>
            <Icon width="20" height="20" />
          </StyledIconContainer>
        )}
        <Text variant="h6">
          {resolveCertificateTitleByCategory(
            certificate.category.toLowerCase()
          )}
        </Text>
        <a href={url}>
          <Button startIcon={<GoToIcon />}>
            <Typography
              variant="body1"
              color="primary"
              whiteSpace="nowrap"
              fontWeight="600"
            >
              Go to
            </Typography>
          </Button>
        </a>
      </StyledCardTitle>
      <div>
        <StyledCardTitleDescription variant="caption1">
          {resolveCertificateIdByCategory(certificate.category.toLowerCase())}
        </StyledCardTitleDescription>
        <Text>{certificate.productId}</Text>
      </div>
    </Card>
  );
};

export default RelatedCertificateCard;
