const routes = {
  index: '/',
  search: '/search',
  productChemistryId: '/chemistry/:productId',
  productBatteryCellsId: '/battery-cells/:productId',
  productBatteryPacksId: '/battery-pack/:productId',
  productBatteryPackRepairsId: '/battery-pack-repair/:productId',
  productBatteryRepurposeId: '/battery-repurpose/:productId',
  productBatteryWasteId: '/battery-waste/:productId',
  productBatteryRecyclingId: '/battery-recycling/:productId',
  productTechReviewId: '/battery-tech-review/:productId',
  certificateId: '/certificate/:productId'
};

export default routes;
