import { useNavigate } from 'react-router-dom';

import BillonLogo from 'src/assets/logo.png';

import { StyledLogoContainer } from './styles';

const BillonLogoLink = () => {
  const navigate = useNavigate();

  return (
    <StyledLogoContainer onClick={() => navigate('/')}>
      <img src={BillonLogo} height="41" alt="logo" />
    </StyledLogoContainer>
  );
};

export default BillonLogoLink;
