import { styled } from '@mui/material';

export const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const StyledLabel = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(1, 1, 0, 0),
  backgroundColor: theme.palette.custom.secondary[100],
  padding: theme.spacing(1)
}));

export const StyledValue = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0, 0, 1, 1),
  border: `1px solid ${theme.palette.custom.secondary[100]}`,
  backgroundColor: theme.palette.custom.textColors[50]
}));

export const Caption = styled('span')(({ theme }) => ({
  color: theme.palette.custom.textColors[600]
}));
