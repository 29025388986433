import { ReactNode } from 'react';

import { ReactComponent as InfoIcon } from 'src/assets/info.svg';

import { Container, Title, Subtitle, TextContainer } from './styles';

type Props = {
  visible: boolean;
  children: ReactNode;
};

const EmptyState = ({ visible, children }: Props) => {
  if (visible) {
    return (
      <Container>
        <InfoIcon />
        <TextContainer>
          <Title variant="body1">
            Sorry, but we don't have information on this
          </Title>
          <Subtitle variant="body2">This field is empty</Subtitle>
        </TextContainer>
      </Container>
    );
  }

  return <>{children}</>;
};

export default EmptyState;
