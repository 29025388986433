import { useParams } from 'react-router-dom';

import Page from 'src/components/page';
import useDocument from 'src/hooks/useDocument';
import BackToSearch from 'src/components/backToSearch';
import HeaderContainer from 'src/components/headerContainer';
import PageHeader from 'src/components/pageHeader';
import PageContainer from 'src/components/pageContainer';
import ContentContainer from 'src/components/pageContentContainer';
import CardContainer from 'src/components/cardContainer';
import Text from 'src/components/text';
import Properties, { PropertiesLayout } from 'src/components/properties';
import CompositionRow from 'src/components/compositionRow';
import DetailsCardFromLabels from 'src/components/detailsCardFromLabels';
import ProducersMap from 'src/components/producersMap';
import Documents from 'src/components/documents/Documents';
import DocumentCategory from 'src/components/documentCategory';

import ProductCard from './components/productCard';
import Scoring from './components/scoring/Product';

const BatteryCells = () => {
  const { productId } = useParams();
  const { data: product } = useDocument(productId as string, {
    enabled: Boolean(productId)
  });

  return (
    <Page title="Battery Product Passport">
      <PageContainer>
        <BackToSearch />
        <HeaderContainer>
          <PageHeader>Battery Cell Certificate</PageHeader>
          <DocumentCategory document={product} />
        </HeaderContainer>
        <ContentContainer>
          <div>
            <CardContainer>
              <ProductCard product={product} />
              <DetailsCardFromLabels
                product={product}
                labels={[
                  'Battery Cell Model',
                  'Chemistry',
                  'Technical Spec',
                  'Certificates of Conformity',
                  'Taric Code',
                  'Battery Cell Global Trade Identification Number',
                  'Battery Cell Production Date',
                  'Company Full Name'
                ]}
                dateLabels={['Battery Cell Production Date']}
              />
            </CardContainer>
          </div>
          <Documents
            document={product}
            relatedFields={['chemistryBatchNumber']}
          />
          <Scoring product={product} />
          <div>
            <Text variant="h5" hasBorder>
              Specification
            </Text>
            <PropertiesLayout>
              <Properties>
                <Text color="label">General info</Text>
                <CompositionRow
                  displayEmpty
                  labels={[
                    'Weight',
                    'Gravimetric energy density',
                    'Internal Resistance',
                    'Nominal Capacity',
                    'Average Nominal Capacity',
                    'Voltage (max Charge)',
                    'Nominal Voltage',
                    'Energy',
                    'Format:'
                  ]}
                  composition={product?.details}
                />
              </Properties>
              <Properties>
                <Text color="label">Operational info</Text>
                <DetailsCardFromLabels
                  product={product}
                  labels={[
                    'Charging Method',
                    'Charging Current',
                    'Charging Time',
                    'Charging Cycles',
                    'Discharge Power'
                  ]}
                />
              </Properties>
            </PropertiesLayout>
          </div>
          <div>
            <ProducersMap title="Battery Cell producers" product={product} />
          </div>
        </ContentContainer>
      </PageContainer>
    </Page>
  );
};

export default BatteryCells;
