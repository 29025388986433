import { Components, Theme } from '@mui/material';

export const MuiButtonComponentConfiguration: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1, 2.75),
      borderRadius: '4px',
      fontWeight: 600,
      textTransform: 'none'
    }),
    sizeSmall: ({ theme }) => ({
      ...theme.typography.buttonSmall
    })
  },
  variants: [
    {
      props: { variant: 'help' },
      style: ({ theme }) => ({
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.custom.neutral[900]
      })
    },
    {
      props: { variant: 'gradient' },
      style: ({ theme }) => ({
        ...theme.typography.caption2,
        fontWeight: 600,
        color: theme.palette.custom.neutral[900],
        borderRadius: theme.spacing(2),
        padding: 1,
        pointerEvents: 'none',
        background: `linear-gradient(to right, ${theme.palette.custom.gradient[50]}, ${theme.palette.custom.gradient[900]})`,
        [theme.breakpoints.only('mobile')]: {
          fontSize: 9
        }
      })
    }
  ]
};
