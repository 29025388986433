import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { resolveProductUrl } from 'src/utils/url';
import FormTextField from 'src/components/form/formTextField';
import useSearchDocument from 'src/hooks/useSearchDocument';
import { ReactComponent as SearchIcon } from 'src/assets/search.svg';
import routes from 'src/constants/routes';
import { RECENTLY_SEARCH_NUMBERS } from 'src/pages/search/constants';
import { SearchRowType } from 'src/pages/root/types';

import { FormContainer } from './styles';

type SearchForm = {
  search: string;
};

const Form = () => {
  const [emptySearch, setEmptySearch] = useState(false);
  const [searches, setSearches] = useLocalStorage<SearchRowType[]>(
    'search',
    []
  );
  const navigate = useNavigate();
  const formProps = useForm<SearchForm>();
  const { mutateAsync: getDocumentList, isLoading } = useSearchDocument({
    onError: () => {
      setEmptySearch(true);
    }
  });

  const handleSubmit = useCallback(
    async (form: SearchForm) => {
      if (form.search.length === 0) {
        return;
      }

      try {
        const documentList = await getDocumentList(form.search);

        if (documentList.length > 0) {
          if (documentList.length === 1) {
            const document = documentList[0];
            const url = resolveProductUrl(document.category).replace(
              ':productId',
              encodeURI(documentList[0].documentBlockchainAddress)
            );

            setSearches(
              [
                {
                  blockchainAddress: document.documentBlockchainAddress,
                  title: document.productId,
                  url: resolveProductUrl(document.category).replace(
                    ':productId',
                    encodeURI(document.documentBlockchainAddress)
                  ),
                  category: document.category
                },
                ...searches
              ].slice(0, RECENTLY_SEARCH_NUMBERS)
            );
            navigate(url);
          } else {
            navigate(`${routes.search}?q=${encodeURIComponent(form.search)}`);
          }
        } else {
          setEmptySearch(true);
        }
      } catch {
        setEmptySearch(true);
      }
    },
    [getDocumentList, navigate, searches, setSearches]
  );

  return (
    <FormContainer>
      <FormProvider {...formProps}>
        <form onSubmit={formProps.handleSubmit(handleSubmit)}>
          <FormTextField
            helperText={
              emptySearch
                ? 'No search results. Sorry, but no results were found matching your search.'
                : ''
            }
            error={emptySearch}
            trim
            disabled={isLoading}
            fullWidth
            variant="outlined"
            placeholder="Enter the Certificate UID"
            InputProps={{
              ...formProps.register('search'),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <div>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
            >
              <span>Search</span>
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </FormContainer>
  );
};

export default Form;
