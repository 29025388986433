import Card from 'src/components/card';
import Label from 'src/components/label';
import Text from 'src/components/text';
import { Document } from 'src/types/document';
import CardRow from 'src/components/cardRow';
import Skeleton from 'src/components/skeleton';
import { getByLabel } from 'src/utils/documentProperties';

type Props = {
  product?: Document;
};

const ProductCard = ({ product }: Props) => {
  if (!product) {
    return <Skeleton height="270px" />;
  }

  return (
    <Card>
      <div>
        <Label>Battery Pack Serial Number</Label>
        <Text>{getByLabel(product.details, 'batteryPackSerialNumber')}</Text>
      </div>
      <div>
        <Label>Passport ID</Label>
        <Text wordBreak>{product.documentBlockchainAddress}</Text>
      </div>
      <CardRow>
        <div>
          <Label>Assembly number</Label>
          <Text>{product.assemblyNumber}</Text>
        </div>
        <div>
          <Label>Control number</Label>
          <Text>{product.controlNumber}</Text>
        </div>
      </CardRow>
    </Card>
  );
};

export default ProductCard;
