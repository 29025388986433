import { styled } from '@mui/material';

import CardRow from 'src/components/cardRow';
import Card from 'src/components/card';
import Text from 'src/components/text';

export const StyledCardRow = styled(CardRow)(() => ({
  '> div:nth-of-type(1)': {
    minWidth: 460
  },
  '> div:nth-of-type(2)': {
    flex: 1
  }
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3)
}));

export const StyledAttachment = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5)
}));

export const StyledCommentHeader = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: 0
}));

StyledCommentHeader.defaultProps = {
  variant: 'h5'
};
