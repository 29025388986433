import { styled } from '@mui/material';

export const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: theme.spacing(2, 17.75),
  color: theme.palette.custom.neutral[300]
}));

export const StyledRegulationsLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  a: {
    textDecoration: 'none'
  }
}));
