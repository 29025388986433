import { createTheme } from '@mui/material/styles';

import palette from './palette';
import breakpoints from './breakpoints';
import components from './components';

const theme = createTheme({
  breakpoints,
  palette,
  components,
  spacing: 8,
  typography: {
    fontFamily: 'Montserrat'
  }
});

export default theme;
