import { Palette } from '@mui/material/styles/createPalette';

export enum Score {
  POOR = 1,
  FAIR = 2,
  GOOD = 3,
  VERY_GOOD = 4
}

const SCALE_WITH_6_OPTIONS = 6;

export const resolveScore = (
  palette: Palette,
  score: number,
  maxScore: number
) => {
  if (maxScore === SCALE_WITH_6_OPTIONS) {
    switch (score) {
      case 1:
        return palette.custom.error[700];
      case 2:
        return palette.custom.error[500];
      case 3:
        return palette.custom.warning[300];
      case 4:
        return palette.custom.warning[400];
      case 5:
        return palette.custom.success[400];
      case 6:
        return palette.custom.success[600];
    }

    return palette.custom.neutral[300];
  }

  switch (score) {
    case Score.POOR:
      return palette.custom.error[700];
    case Score.FAIR:
      return palette.custom.warning[600];
    case Score.GOOD:
      return palette.custom.success[400];
    case Score.VERY_GOOD:
      return palette.custom.success[600];
  }

  return palette.custom.neutral[300];
};
