import { AppBar, styled, Typography } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  padding: theme.spacing(0),
  background: `linear-gradient(90deg, ${theme.palette.custom.primary[50]} 0%, ${theme.palette.custom.textColors[50]} 50%, ${theme.palette.custom.primary[50]} 100%)`,
  '.MuiToolbar-root': {
    margin: theme.spacing(2, 0)
  }
}));

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: '600',
  flex: '1',
  textAlign: 'right'
}));

StyledTypography.defaultProps = {
  variant: 'h5'
};
