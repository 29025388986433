import PageHeader from 'src/components/pageHeader';
import Page from 'src/components/page';

import RootPageContainer from './components/container';
import Description from './components/description';
import Form from './components/form';
import RecentlySearched from './components/recentlySearched';

const RootPage = () => (
  <Page>
    <RootPageContainer>
      <PageHeader textAlign="center">
        Battery Product Passport
        <br />
        search engine
      </PageHeader>
      <Description>
        Please enter Certificate UID to see certificate.
      </Description>
      <Form />
      <RecentlySearched />
    </RootPageContainer>
  </Page>
);

export default RootPage;
