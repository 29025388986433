import { PropsWithChildren } from 'react';

import { Score } from 'src/utils/score';

import {
  CardWithStatusContainer,
  StyledErrorCard,
  StyledRankFairCard,
  StyledRankGoodCard,
  StyledVeryGoodCard
} from './styles';

type Props = {
  rank: Score | null;
};

const RankCard = ({ rank, children }: PropsWithChildren<Props>) => {
  let Card;

  switch (rank) {
    case Score.POOR:
      Card = StyledErrorCard;
      break;
    case Score.FAIR:
      Card = StyledRankFairCard;
      break;
    case Score.GOOD:
      Card = StyledRankGoodCard;
      break;
    case Score.VERY_GOOD:
      Card = StyledVeryGoodCard;
      break;
    default:
      return null;
  }
  return (
    <Card>
      <CardWithStatusContainer>{children}</CardWithStatusContainer>
    </Card>
  );
};

export default RankCard;
