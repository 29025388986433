import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import { SearchRowType } from 'src/pages/root/types';
import { getIconByCategory, getSearchNameByCategory } from 'src/utils/category';

import {
  Container,
  Details,
  DetailsHeader,
  DetailsText,
  IconContainer,
  StyledBlockchainText
} from './styles';

const SearchRow = ({
  title,
  url,
  category,
  onClick,
  blockchainAddress
}: SearchRowType) => {
  const Icon = category && getIconByCategory(category.toLowerCase())?.Icon;

  return (
    <Container>
      <IconContainer>{Icon && <Icon width="26" height="26" />}</IconContainer>
      <Details>
        <DetailsHeader>{getSearchNameByCategory(category)}</DetailsHeader>
        <DetailsText>{title}</DetailsText>
        <StyledBlockchainText>{blockchainAddress}</StyledBlockchainText>
      </Details>
      <Link to={url} onClick={onClick}>
        <Button>Details</Button>
      </Link>
    </Container>
  );
};

export default SearchRow;
