import Text from 'src/components/text';
import { Document } from 'src/types/document';
import Skeleton from 'src/components/skeleton';
import Card from 'src/components/card';
import CardRow from 'src/components/cardRow';
import ProductScore from 'src/pages/battery-packs/components/productScore';
import BatteryPackProducerScore from 'src/pages/battery-packs/components/batteryPackProducerScore';
import BatteryCellsScore from 'src/pages/battery-packs/components/batteryCellsScore';

type Props = {
  product?: Document;
};

const Scoring = ({ product }: Props) => {
  if (!product) {
    return <Skeleton height="367px" />;
  }

  return (
    <div>
      <Text variant="h5" dense>
        Scoring
      </Text>
      <Card>
        <CardRow center>
          <ProductScore product={product} />
          <BatteryPackProducerScore product={product} />
          <BatteryCellsScore product={product} />
        </CardRow>
      </Card>
    </div>
  );
};

export default Scoring;
