import { styled, Typography } from '@mui/material';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontWeight: 600,
  color: theme.palette.custom.textColors[900]
}));

StyledTitle.defaultProps = {
  variant: 'body1'
};

export const StyledSearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}));
