import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { IconButton } from '@mui/material';

import { CertificateComponent } from 'src/types/document';
import { getIconByCategory } from 'src/utils/category';
import Text from 'src/components/text';
import Card from 'src/components/card';
import Label from 'src/components/label';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';
import CardRow from 'src/components/cardRow';
import { ReactComponent as GoToIcon } from 'src/assets/goto.svg';
import { ReactComponent as ArrowUpIcon } from 'src/assets/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'src/assets/arrow-down.svg';
import RecycledMaterials from 'src/pages/certificate/components/RecycledMaterials';

import {
  ComponentPropertiesContainer,
  Container,
  TitleContainer
} from './styles';

const Icon = getIconByCategory('chemistry')?.Icon;

type Props = {
  component: CertificateComponent;
  index: number;
};

const Certificate = ({ component, index }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Card dense shadow>
      <Container>
        <TitleContainer onClick={() => setIsCollapsed(!isCollapsed)}>
          {Icon && <Icon />}
          <Text variant="h6">
            Component {index}{' '}
            <Box component="span" color="custom.textColors.600">
              / Product name:{' '}
            </Box>
            {component.componentName}
          </Text>
          {component.componentPassportId && (
            <a
              href={`/certificate/${component.componentPassportId}`}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Button startIcon={<GoToIcon />}>
                <Typography
                  variant="body1"
                  color="primary"
                  whiteSpace="nowrap"
                  fontWeight="600"
                >
                  Go to certificate
                </Typography>
              </Button>
            </a>
          )}
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
          </IconButton>
        </TitleContainer>
        <Collapse in={!isCollapsed}>
          <ComponentPropertiesContainer>
            <CardRow>
              <RecycledMaterials
                label="% recycled materials"
                value={component.percentRecycled}
              />
              <div>
                <Label>Country of Origin</Label>
                <Text>{component.countryOfOrigin || DEFAULT_VALUE_TEXT}</Text>
              </div>
              <RecycledMaterials
                label="% share in the final product"
                value={Number(component.percentShare)}
              />
              <div>
                <Label>Batch No.</Label>
                <Text>{component.batchNumber || DEFAULT_VALUE_TEXT}</Text>
              </div>
              <div>
                <Label>Component ID</Label>
                <Text>{component.componentId || DEFAULT_VALUE_TEXT}</Text>
              </div>
              <div>
                <Label>Factory</Label>
                <Text>{component.supplierName || DEFAULT_VALUE_TEXT}</Text>
              </div>
            </CardRow>
          </ComponentPropertiesContainer>
        </Collapse>
      </Container>
    </Card>
  );
};

export default Certificate;
