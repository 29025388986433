import { FormProvider, useForm } from 'react-hook-form';
import { Button, InputAdornment } from '@mui/material';
import { useCallback, useState } from 'react';

import Certificate from 'src/pages/certificate/components/CertificateComponent';
import FormTextField from 'src/components/form/formTextField';
import { ReactComponent as SearchIcon } from 'src/assets/search.svg';
import { CertificateComponent } from 'src/types/document';

import {
  Container,
  StyledComponentTitle,
  StyledSearchContainer
} from './styles';

type Props = {
  components: CertificateComponent[];
};

interface SearchForm {
  search: string;
}

const CertificateComponents = ({ components }: Props) => {
  const formProps = useForm<SearchForm>();
  const [filteredComponents, setComponents] = useState(components || []);
  const handleSubmit = useCallback(
    ({ search }: SearchForm) => {
      if (!components) {
        return;
      }
      setComponents(
        search
          ? components.filter(p =>
              p.componentName.toLowerCase().includes(search.toLowerCase())
            )
          : components
      );
    },
    [components]
  );

  return (
    <Container>
      <StyledSearchContainer>
        <StyledComponentTitle variant="h5">
          Component information ({filteredComponents.length})
        </StyledComponentTitle>
        <FormProvider {...formProps}>
          <form onSubmit={formProps.handleSubmit(handleSubmit)}>
            <FormTextField
              trim
              fullWidth
              variant="outlined"
              placeholder="Search by product name"
              InputProps={{
                ...formProps.register('search'),
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <div>
              <Button type="submit" variant="outlined">
                <span>Search</span>
              </Button>
            </div>
          </form>
        </FormProvider>
      </StyledSearchContainer>
      {filteredComponents.map((component, index) => (
        <Certificate
          key={component.componentId}
          component={component}
          index={index + 1}
        />
      ))}
    </Container>
  );
};

export default CertificateComponents;
