import Label from 'src/components/label';
import Scoring from 'src/components//scoring';
import { getByLabel } from 'src/utils/documentProperties';
import Card from 'src/components/card';
import Text from 'src/components/text';
import { Document } from 'src/types/document';
import ScoringLabel from 'src/components/scoring/ScoringLabel';

type Props = {
  product?: Document;
};

const ProductScore = ({ product }: Props) => {
  if (!product) {
    return null;
  }

  return (
    <Card shadow dense>
      <ScoringLabel label="Product score" category="batterypacks" />
      <div>
        <Label>Battery Pack Batch No</Label>
        <Text>{product.productId}</Text>
      </div>
      <div>
        <Label>Battery Pack Child Labor Average</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.details, 'batteryPackChildLaborAverage')}
        />
      </div>
      <div>
        <Label>Battery Pack Human Rights Average</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.details, 'batteryPackHumanRightsAverage')}
        />
      </div>
      <div>
        <Label>Battery Pack ESG scoring Average</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(
            product.details,
            'batteryPackEsgScoringAverage(6max,1Min)'
          )}
        />
      </div>
    </Card>
  );
};
export default ProductScore;
