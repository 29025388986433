import { styled, Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.custom.secondary[50],
  padding: theme.spacing(0.8, 2),
  display: 'flex',
  gap: '12px',
  svg: {
    marginTop: theme.spacing(1)
  }
}));

export const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1, 0)
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.custom.secondary[700]
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.custom.secondary[700]
}));
