import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { useGetDocument } from 'src/hooks/useSearchDocument';
import { SearchRowType } from 'src/pages/root/types';
import { resolveProductUrl } from 'src/utils/url';
import PageHeader from 'src/components/pageHeader';
import SearchRow from 'src/components/searchRow';
import { RECENTLY_SEARCH_NUMBERS } from 'src/pages/search/constants';

import { StyledSearchContainer } from './styles';

const SearchResults = () => {
  const [searches, setSearches] = useLocalStorage<SearchRowType[]>(
    'search',
    []
  );

  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');
  const { data } = useGetDocument(query as string, {
    enabled: Boolean(query)
  });

  return (
    <>
      <PageHeader textAlign="center">
        Search results for <br /> "{query}"
      </PageHeader>
      <StyledSearchContainer>
        {data?.map(d => (
          <SearchRow
            blockchainAddress={d.documentBlockchainAddress}
            key={d.documentBlockchainAddress}
            category={d.category}
            title={d.productId}
            onClick={() => {
              setSearches(
                [
                  {
                    title: d.productId,
                    url: resolveProductUrl(d.category).replace(
                      ':productId',
                      encodeURI(d.documentBlockchainAddress)
                    ),
                    blockchainAddress: d.documentBlockchainAddress,
                    category: d.category
                  },
                  ...searches
                ].slice(0, RECENTLY_SEARCH_NUMBERS)
              );
            }}
            url={resolveProductUrl(d.category).replace(
              ':productId',
              encodeURI(d.documentBlockchainAddress)
            )}
          />
        ))}
      </StyledSearchContainer>
    </>
  );
};

export default SearchResults;
