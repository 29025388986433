import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/constants/api';

const downloadDocumentByBlockchainAddress = (blockchainAddress: string) => {
  const apiClient = getApiClient(DOCUMENT_API_URL, {
    responseType: 'blob'
  });

  return apiClient.get<Blob>(
    apiRoutes.documentDownloadByBlockchainAddressId.replace(
      '{documentId}',
      blockchainAddress
    )
  );
};

export default downloadDocumentByBlockchainAddress;
