import { Document } from 'src/types/document';
import Label from 'src/components/label';
import Text from 'src/components/text';
import { getByLabel } from 'src/utils/documentProperties';

type Props = {
  product: Document;
};

const CertificateDetails = ({ product }: Props) => {
  switch (product.category.toLowerCase()) {
    case 'chemistry':
      return (
        <>
          <div>
            <Label>Chemistry market name</Label>
            <Text>{product.chemistryMarketName}</Text>
          </div>
          <div>
            <Label>Chemistry batch number</Label>
            <Text>{product.productId}</Text>
          </div>
        </>
      );
    case 'batterypacks':
      return (
        <>
          <div>
            <Label>Battery Cell Model</Label>
            <Text>
              {getByLabel(product.details, 'Battery Cell Model') ||
                getByLabel(product.details, 'batteryCellModel')}
            </Text>
          </div>
          {getByLabel(product.details, 'batteryPackSerialNumber') && (
            <div>
              <Label>Battery Pack Serial Number</Label>
              <Text>
                {getByLabel(product.details, 'batteryPackSerialNumber')}
              </Text>
            </div>
          )}
        </>
      );
    case 'batteriestechreview':
    case 'batterypacksrepair':
    case 'batterypacksrecycling':
    case 'batterywaste':
    case 'batteryrepurpose':
    case 'batterycells':
      return (
        <>
          <div>
            <Label>Battery Cell Model</Label>
            <Text>
              {getByLabel(product.details, 'Battery Cell Model') ||
                getByLabel(product.details, 'Battery Cell Model')}
            </Text>
          </div>
          <div>
            <Label>Battery Cells Batch Number</Label>
            <Text>{product.productId}</Text>
          </div>
        </>
      );
    default:
      return (
        <>
          <div>
            <Label>Chemistry batch number</Label>
            <Text>{product.productId}</Text>
          </div>
        </>
      );
  }
};

export default CertificateDetails;
