import { Document } from 'src/types/document';
import useRelatedDocuments from 'src/hooks/useRelatedDocuments';
import Skeleton from 'src/components/skeleton';
import Text from 'src/components/text';
import DownloadDocument from 'src/components/downloadDocument';
import EmptyState from 'src/components/emptyState';

import { Container } from './styles';

type Props = {
  product: Document;
};

const RelatedDocuments = ({ product }: Props) => {
  const { data, isLoading } = useRelatedDocuments(
    product.documentBlockchainAddress,
    product.publisherId
  );

  if (isLoading) {
    return <Skeleton height="58px" />;
  }

  return (
    <EmptyState visible={!data}>
      {data?.map((product, index) => (
        <Container key={`text_${index}`}>
          <Text>{`${product.title}.pdf`}</Text>
          <DownloadDocument product={product} />
        </Container>
      ))}
    </EmptyState>
  );
};

export default RelatedDocuments;
