import { ReactComponent as ScienceIcon } from 'src/assets/category/science.svg';
import { ReactComponent as BatteryIcon } from 'src/assets/category/battery.svg';
import { ReactComponent as BatteryPack } from 'src/assets/category/battery-pack.svg';
import { ReactComponent as PreviewIcon } from 'src/assets/category/preview.svg';
import { ReactComponent as ConstructionIcon } from 'src/assets/category/construction.svg';
import { ReactComponent as RecycleIcon } from 'src/assets/category/recycle.svg';
import { ReactComponent as EarthIcon } from 'src/assets/category/earth.svg';
import { ReactComponent as ThrashIcon } from 'src/assets/category/trash.svg';
import { ReactComponent as ProducerIcon } from 'src/assets/category/producer.svg';
import { ReactComponent as FactoryIcon } from 'src/assets/category/factory.svg';
import { ReactComponent as CertificateIcon } from 'src/assets/category/certificate.svg';

export const categoryIconMapping = [
  {
    searchName: 'General Battery Certificate',
    category: 'certificate',
    Icon: CertificateIcon
  },
  {
    name: '',
    category: 'factory',
    Icon: FactoryIcon
  },
  {
    name: '',
    category: 'producer',
    Icon: ProducerIcon
  },
  {
    name: 'Battery Cell Chemistry Certificate',
    category: 'chemistry',
    Icon: ScienceIcon
  },
  {
    name: 'Battery Cell Certificate',
    category: 'batterycells',
    Icon: BatteryIcon
  },
  {
    name: 'Battery Pack Certificate',
    category: 'batterypacks',
    Icon: BatteryPack
  },
  {
    name: 'Battery Pack Technical Review Certificate',
    category: 'batteriestechreview',
    Icon: PreviewIcon
  },
  {
    name: 'Battery Pack Repair Certificate',
    category: 'batterypacksrepair',
    Icon: ConstructionIcon
  },
  {
    name: 'Battery Pack Repurpose Certificate',
    category: 'batteryrepurpose',
    Icon: RecycleIcon
  },
  {
    name: 'Battery Pack Recycling Certificate',
    category: 'batterypacksrecycling',
    Icon: EarthIcon
  },
  {
    name: 'Battery Waste',
    category: 'batterywaste',
    Icon: ThrashIcon
  }
];

export const getIconByCategory = (category: string) =>
  categoryIconMapping.find(i => i.category === category.toLowerCase());

export const getNameByCategory = (category?: string) =>
  categoryIconMapping.find(i => i.category === category?.toLowerCase())?.name;

export const getSearchNameByCategory = (category?: string) => {
  const result = categoryIconMapping.find(
    i => i.category === category?.toLowerCase()
  );

  return result?.searchName || result?.name;
};
