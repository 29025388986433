import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Skeleton from 'src/components/skeleton';
import useSearchDocument from 'src/hooks/useSearchDocument';
import { ReactComponent as GoToIcon } from 'src/assets/goto.svg';
import { resolveProductUrl } from 'src/utils/url';
import EmptyState from 'src/components/emptyState';

import { Container } from './styles';
import CertificateDetails from './CertifcateName';

type Props = {
  productId: string;
};

const RelatedCertificates = ({ productId }: Props) => {
  const { mutate, isLoading, data } = useSearchDocument();

  useEffect(() => {
    mutate(productId);
  }, [mutate, productId]);
  if (isLoading) {
    return <Skeleton height="58px" />;
  }

  return (
    <EmptyState visible={!data}>
      {data?.map((product, index) => {
        const url = resolveProductUrl(product.category).replace(
          ':productId',
          encodeURI(product.documentBlockchainAddress)
        );

        return (
          <Container key={`text_${index}`}>
            <CertificateDetails product={product} />
            <a href={url}>
              <Button startIcon={<GoToIcon />}>
                <Typography
                  variant="body1"
                  color="primary"
                  whiteSpace="nowrap"
                  fontWeight="600"
                >
                  Go to
                </Typography>
              </Button>
            </a>
          </Container>
        );
      })}
    </EmptyState>
  );
};

export default RelatedCertificates;
