import { styled } from '@mui/material';

export const FormContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  form: {
    flexDirection: 'column',
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'flex-end'
  }
}));
