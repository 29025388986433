import Label from 'src/components/label';
import Scoring from 'src/components//scoring';
import { getByLabel } from 'src/utils/documentProperties';
import Card from 'src/components/card';
import Text from 'src/components/text';
import { Document } from 'src/types/document';
import ScoringLabel from 'src/components/scoring/ScoringLabel';

type Props = {
  product?: Document;
};

const ProductScore = ({ product }: Props) => {
  if (!product) {
    return null;
  }

  return (
    <Card shadow dense>
      <ScoringLabel label="Product score" category={product.category} />
      <div>
        <Label>Battery Cells Batch No</Label>
        <Text>{product.productId}</Text>
      </div>
      <div>
        <Label>Battery Cell Child Labor Scoring</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.details, 'batteryCellChildLaborScoring')}
        />
      </div>
      <div>
        <Label>Battery Cells Human Rights Average</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.details, 'batteryCellsHumanRightsAverage')}
        />
      </div>
    </Card>
  );
};
export default ProductScore;
