import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/constants/api';
import {
  Document,
  DocumentAdditionalDetails,
  GetRelatedDocumentsResponse
} from 'src/types/document';

const getDocumentHistory = async (
  documentId: string,
  publisherId: string
): Promise<Document[]> => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  const response = await apiClient.get<GetRelatedDocumentsResponse>(
    apiRoutes.documentHistoryByBlockchainId
      .replace('{documentId}', encodeURI(documentId))
      .replace('{publisherId}', publisherId)
  );

  return response.data.relatedDocuments.map(r => ({
    documentBlockchainAddress: r.documentBlockchainAddress,
    publisherId: r.publisherId,
    category: r.category,
    title: r.title,
    ...(r.additionalDetails
      ? JSON.parse(r.additionalDetails)
      : ({} as DocumentAdditionalDetails))
  }));
};

export default getDocumentHistory;
