import getApiClient from 'src/lib/getApiClient';
import apiRoutes from 'src/constants/apiRoutes';
import { DOCUMENT_API_URL } from 'src/constants/api';
import {
  Document,
  DocumentAdditionalDetails,
  GetDocumentResponse
} from 'src/types/document';

const getDocument = async (documentId: string): Promise<Document> => {
  const apiClient = getApiClient(DOCUMENT_API_URL);

  const response = await apiClient.get<GetDocumentResponse>(
    apiRoutes.documentByBlockchainId.replace(
      '{documentId}',
      encodeURI(documentId)
    )
  );
  const document = {
    documentBlockchainAddress: response.data.documentBlockchainAddress,
    category: response.data.category,
    title: response.data.title,
    publisherId: response.data.publisherId,
    ...(JSON.parse(
      response.data.additionalDetails
    ) as DocumentAdditionalDetails)
  };

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.info('[Debug document]', document);
  }

  return document;
};

export default getDocument;
