import { styled } from '@mui/material';

export const StyledCompositionRow = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
  display: 'flex',
  flexWrap: 'wrap',
  '> div': {
    minWidth: '120px',
    flexShrink: 0
  }
}));
