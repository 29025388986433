export const formatBlockchainAddress = (
  address: string | undefined | null,
  firstBreakpoint = 4,
  secondBreakpoint = 4
) => {
  if (!address) {
    return address;
  }

  return `${address.slice(0, firstBreakpoint)}...${address.slice(
    address.length - secondBreakpoint,
    address.length
  )}`;
};
