import { useParams } from 'react-router-dom';

import Page from 'src/components/page';
import useDocument from 'src/hooks/useDocument';
import BackToSearch from 'src/components/backToSearch';
import HeaderContainer from 'src/components/headerContainer';
import PageHeader from 'src/components/pageHeader';
import PageContainer from 'src/components/pageContainer';
import ContentContainer from 'src/components/pageContentContainer';
import CardContainer from 'src/components/cardContainer';
import Text from 'src/components/text';
import Properties, { PropertiesLayout } from 'src/components/properties';
import Card, { RankCard } from 'src/components/card';
import Label from 'src/components/label';
import CardRow from 'src/components/cardRow';
import Scoring from 'src/components/scoring';
import { Column } from 'src/components/flex';
import CompositionRow from 'src/components/compositionRow';
import { formatByLabel, getByLabel } from 'src/utils/documentProperties';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';
import DocumentCategory from 'src/components/documentCategory';
import DocumentCertificates from 'src/components/documents/DocumentCertificates';
import EmptyState from 'src/components/emptyState';

import ProductCard from './components/productCard';
import DetailsCard from './components/detailsCard';
import ProducersMap from './components/producersMap';

const getScore = (scoreString?: string) => {
  switch (scoreString) {
    case 'Poor':
      return 1;
    case 'Fair':
      return 2;
    case 'Good':
      return 3;
    case 'Very good':
      return 4;
    default:
      return null;
  }
};

const BatteryPackRepair = () => {
  const { productId } = useParams();
  const { data: product } = useDocument(productId as string, {
    enabled: Boolean(productId)
  });
  const scoreStr = getByLabel(product?.details, 'healthDescription');
  const score = getScore(scoreStr);

  return (
    <Page title="Battery Product Passport">
      <PageContainer>
        <BackToSearch />
        <HeaderContainer>
          <PageHeader>Battery Pack Repair Certificate</PageHeader>
          <DocumentCategory document={product} />
        </HeaderContainer>
        <ContentContainer>
          <div>
            <CardContainer>
              <ProductCard product={product} />
              <DetailsCard product={product} />
            </CardContainer>
          </div>
          <CardContainer>
            <div style={{ flex: 1 }}>
              <Text variant="h5" hasBorder>
                Repair description
              </Text>
              <Properties>
                <EmptyState
                  visible={
                    !getByLabel(product?.details, 'repairDescription') &&
                    !getByLabel(product?.details, 'repairDate')
                  }
                >
                  <Properties>
                    <Text color="label">Repair info</Text>
                    <Card>
                      <CardRow>
                        <div>
                          <Label>Repair description</Label>
                          <Text>
                            {getByLabel(product?.details, 'repairDescription')}
                          </Text>
                        </div>
                        <div>
                          <Label>Repair date</Label>
                          <Text>
                            {formatByLabel(product?.details, 'repairDate', {
                              dateLabels: ['repairDate']
                            })}
                          </Text>
                        </div>
                      </CardRow>
                    </Card>
                  </Properties>
                </EmptyState>
                <Properties>
                  <Text color="label">Health description</Text>
                  <RankCard rank={score}>
                    <Text>{scoreStr}</Text>
                    {score && (
                      <Scoring
                        maxScore={4}
                        score={score.toString()}
                        hideLabel
                      />
                    )}
                  </RankCard>
                </Properties>
              </Properties>
            </div>
            <DocumentCertificates
              document={product}
              relatedFields={[
                'batteryCellsBatchNumber',
                'batteryPackSerialNumber'
              ]}
            />
          </CardContainer>

          <div>
            <Text variant="h5" hasBorder>
              Specification
            </Text>
            <Column>
              <PropertiesLayout>
                <Properties>
                  <Text color="label">General info</Text>
                  <CompositionRow
                    composition={product?.details}
                    labels={[
                      'Cells setup',
                      'Total Capacity',
                      'Usable Capacity',
                      'Weight',
                      'Gravimetric energy density',
                      'Cooling',
                      'Storage Temp Range'
                    ]}
                  />
                </Properties>
              </PropertiesLayout>
              <Properties>
                <Text color="label">Description</Text>
                <Card dense>
                  <Text>
                    {getByLabel(
                      product?.details,
                      'description',
                      DEFAULT_VALUE_TEXT
                    )}
                  </Text>
                </Card>
              </Properties>
            </Column>
          </div>
          <div>
            <ProducersMap title="Repair company" product={product} />
          </div>
        </ContentContainer>
      </PageContainer>
    </Page>
  );
};

export default BatteryPackRepair;
