import Text from 'src/components/text';
import Card from 'src/components/card';
import { Document } from 'src/types/document';
import Skeleton from 'src/components/skeleton';
import CardRow from 'src/components/cardRow';
import Label from 'src/components/label';
import { formatByLabel } from 'src/utils/documentProperties';

type Props = {
  labels: string[];
  dateLabels?: string[];
  product?: Document;
  emptyBackground?: boolean;
  camelizeLabel?: boolean;
};

const DetailsCardFromLabels = ({
  product,
  labels,
  dateLabels = [],
  camelizeLabel = true,
  emptyBackground
}: Props) => {
  if (!product) {
    return <Skeleton height="270px" />;
  }

  if (!product.details) {
    return null;
  }

  const properties = [...(product.details || []), ...(product.producer || [])];

  if (emptyBackground) {
    return (
      <CardRow>
        {labels.map((label, index) => (
          <div key={`details_${index}`}>
            <Label>{label}</Label>
            <Text>
              {formatByLabel(properties, label, {
                dateLabels,
                camelizeLabel
              })}
            </Text>
          </div>
        ))}
      </CardRow>
    );
  }

  return (
    <Card>
      <CardRow wrap>
        {labels.map((label, index) => (
          <div key={`details_${index}`}>
            <Label>{label}</Label>
            <Text>
              {formatByLabel(properties, label, {
                dateLabels,
                camelizeLabel
              })}
            </Text>
          </div>
        ))}
      </CardRow>
    </Card>
  );
};

export default DetailsCardFromLabels;
