import { useLocalStorage } from 'usehooks-ts';

import { SearchRowType } from 'src/pages/root/types';
import SearchRow from 'src/components/searchRow';

import { StyledTitle, StyledSearchContainer } from './styles';

const RecentlySearched = () => {
  const [searches] = useLocalStorage<SearchRowType[]>('search', []);

  if (searches.length === 0) {
    return null;
  }

  return (
    <>
      <StyledTitle>Recently searched:</StyledTitle>
      <StyledSearchContainer>
        {searches.map((search, index) => (
          <SearchRow {...search} key={`${search.title}_${index}`} />
        ))}
      </StyledSearchContainer>
    </>
  );
};

export default RecentlySearched;
