const apiRoutes = {
  documentByProductId: '/documents/public/product/{productId}',
  documentDownloadByBlockchainAddressId:
    '/documents/public/{documentId}/download',
  documentByBlockchainId: '/documents/public/{documentId}',
  documentHistoryByBlockchainId: `documents/public/{documentId}/history?publisherId={publisherId}`,
  documentRelatedCertificatesId: `documents/public/relatedCertificates/{documentId}`
};

export default apiRoutes;
