import { styled } from '@mui/material';

export const RootPageContainer = styled('div')(({ theme }) => ({
  flex: 1,
  margin: '0 auto',
  maxWidth: theme.spacing(80),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(9)
}));
