import { PropsWithChildren } from 'react';

import { CardWithStatusContainer, StyledErrorCard } from './styles';

const ErrorCard = ({ children }: PropsWithChildren) => (
  <StyledErrorCard>
    <CardWithStatusContainer>{children}</CardWithStatusContainer>
  </StyledErrorCard>
);

export default ErrorCard;
