import { DocumentProperties } from 'src/types/document';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';

import { parseDate } from './date';

export const getByLabel = (
  properties: DocumentProperties[] | undefined,
  name: string,
  defaultValue = ''
) => properties?.find(({ label }) => name === label)?.value || defaultValue;

type FormatByLabelOptions = {
  defaultValue?: string;
  dateLabels?: string[];
  percentLabels?: string[];
  camelizeLabel?: boolean;
};
export const formatByLabel = (
  properties: DocumentProperties[] | undefined,
  name: string,
  props: FormatByLabelOptions = {
    defaultValue: DEFAULT_VALUE_TEXT,
    camelizeLabel: false,
    percentLabels: [],
    dateLabels: []
  }
) => {
  const value = getByLabel(
    properties,
    props.camelizeLabel ? camelize(name) : name,
    props.defaultValue || DEFAULT_VALUE_TEXT
  );

  if (props.dateLabels?.includes(name)) {
    return parseDate(value);
  }

  if (props.percentLabels?.includes(name)) {
    return `${Number(value)}%`;
  }

  return value;
};

export const camelize = (str: string) => {
  // HACK
  if (str === 'Voltage (max Charge)') {
    return 'voltage(maxCharge)';
  }

  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');
};

export const camelizeArray = (array: string[]) => array.map(camelize);
