import Label from 'src/components/label';
import Scoring from 'src/components//scoring';
import { getByLabel } from 'src/utils/documentProperties';
import Card from 'src/components/card';
import Text from 'src/components/text';
import { Document } from 'src/types/document';
import { useGetDocument } from 'src/hooks/useSearchDocument';
import Properties from 'src/components/properties';
import ScoringLabel from 'src/components/scoring/ScoringLabel';

type Props = {
  product?: Document;
};

const ChemistryScore = ({ product }: Props) => {
  const chemistryBatchNumber = getByLabel(
    product?.details,
    'chemistryBatchNumber'
  );
  const { data: products } = useGetDocument(chemistryBatchNumber as string, {
    enabled: Boolean(chemistryBatchNumber)
  });
  const chemistryProduct =
    products && products.length === 1 ? products[0] : false;

  if (!product || !chemistryProduct) {
    return null;
  }

  return (
    <>
      <Card shadow dense>
        <ScoringLabel label="Chemistry score" category="chemistry" />
        <div>
          <Label>Chemistry batch number</Label>
          <Text>{chemistryProduct.productId}</Text>
        </div>
        <div>
          <Label>Materials Child Labor Average</Label>
          <Scoring
            maxScore={6}
            score={getByLabel(product.details, 'materialsChildLaborAverage')}
          />
        </div>
        <div>
          <Label>Materials Human Rights Average</Label>
          <Scoring
            maxScore={6}
            score={getByLabel(product.details, 'materialsHumanRightsAverage')}
          />
        </div>
      </Card>
      <Properties>
        <Card shadow dense>
          <ScoringLabel label="Lithium Producer" category="producer" />
          <div>
            <Label>Company</Label>
            <Text>
              {getByLabel(chemistryProduct.lithiumProducer, 'company')}
            </Text>
          </div>
          <div>
            <Label>Materials Child Labor Average</Label>
            <Scoring
              maxScore={6}
              score={getByLabel(
                chemistryProduct.lithiumProducer,
                'childLaborScoring(6Max,1Min)'
              )}
            />
          </div>
          <div>
            <Label>Materials Human Rights Average</Label>
            <Scoring
              maxScore={6}
              score={getByLabel(
                chemistryProduct.lithiumProducer,
                'humanRightsScoring(6max,1Min)'
              )}
            />
          </div>
        </Card>
        <Card shadow dense>
          <ScoringLabel label="Cobalt Producer" category="producer" />
          <div>
            <Label>Company</Label>
            <Text>
              {getByLabel(chemistryProduct.cobaltProducer, 'company')}
            </Text>
          </div>
          <div>
            <Label>Materials Child Labor Average</Label>

            <Scoring
              maxScore={6}
              score={getByLabel(
                chemistryProduct.cobaltProducer,
                'childLaborScoring(6Max,1Min)'
              )}
            />
          </div>
          <div>
            <Label>Materials Human Rights Average</Label>
            <Scoring
              maxScore={6}
              score={getByLabel(
                chemistryProduct.cobaltProducer,
                'humanRightsScoring(6max,1Min)'
              )}
            />
          </div>
        </Card>
      </Properties>
    </>
  );
};
export default ChemistryScore;
