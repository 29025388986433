import { Typography } from '@mui/material';

import { ReactComponent as PoweredByIcon } from 'src/assets/poweredBy.svg';

import { StyledContent } from './styles';

const PoweredBy = () => {
  const appVersion = `v ${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_PIPELINE_ID}`;

  return (
    <StyledContent>
      <Typography variant="caption1">
        Powered by <PoweredByIcon /> {appVersion}
      </Typography>
    </StyledContent>
  );
};

export default PoweredBy;
