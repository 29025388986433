import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  button: {
    padding: 0,
    marginLeft: theme.spacing(1),
    span: {
      color: theme.palette.custom.secondary[500]
    }
  }
}));

export const ValueContainer = styled('div')(() => ({
  display: 'flex'
}));
