import { styled } from '@mui/material';

export const StyledBaseLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.grey[50],
  paddingTop: theme.spacing(14),
  minHeight: '100vh',
  overflow: 'hidden',
  background: `linear-gradient(90deg, ${theme.palette.custom.primary[50]} 0%, ${theme.palette.custom.textColors[50]} 50%, ${theme.palette.custom.primary[50]} 100%)`
}));

export const BackgroundPartContainer = styled('div')(() => ({
  position: 'absolute',
  left: 0,
  bottom: 0
}));
