import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import downloadDocument from 'src/api/downloadDocument';

export const useDownloadDocument = (
  options?: UseMutationOptions<
    void,
    AxiosError,
    { blockchainAddress: string; title: string }
  >
) =>
  useMutation<void, AxiosError, { blockchainAddress: string; title: string }>(
    async ({ blockchainAddress, title }) => {
      const document = await downloadDocument(blockchainAddress);
      const blob = new Blob([document.data], {
        type: document.headers['content-type']
      });

      const link = window.document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = title;
      window.document.body.append(link);
      link.click();
      link.remove();

      setTimeout(() => URL.revokeObjectURL(link.href));
    },
    options
  );

export default useDownloadDocument;
