import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom';

import routes from 'src/constants/routes';
import BaseLayout from 'src/components/baseLayout';
import RootPage from 'src/pages/root';
import Chemistry from 'src/pages/chemistry';
import BatteryCells from 'src/pages/battery-cells';
import BatteryPacks from 'src/pages/battery-packs';
import BatteryTechReview from 'src/pages/battery-tech-review';
import BatteryPackRepair from 'src/pages/battery-pack-repair';
import BatteryRepurpose from 'src/pages/battery-repurpose';
import BatteryRecycling from 'src/pages/battery-recycling';
import BatteryWaste from 'src/pages/battery-waste';
import Certificate from 'src/pages/certificate';
import SearchPage from 'src/pages/search';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.index} element={<BaseLayout />}>
      <Route index element={<RootPage />} />
      <Route path={routes.productChemistryId} element={<Chemistry />} />
      <Route path={routes.search} element={<SearchPage />} />
      <Route path={routes.productBatteryCellsId} element={<BatteryCells />} />
      <Route
        path={routes.productTechReviewId}
        element={<BatteryTechReview />}
      />
      <Route
        path={routes.productBatteryPackRepairsId}
        element={<BatteryPackRepair />}
      />
      <Route
        path={routes.productBatteryRecyclingId}
        element={<BatteryRecycling />}
      />
      <Route path={routes.productBatteryWasteId} element={<BatteryWaste />} />
      <Route
        path={routes.productBatteryRepurposeId}
        element={<BatteryRepurpose />}
      />
      <Route path={routes.productBatteryPacksId} element={<BatteryPacks />} />
      <Route path={routes.certificateId} element={<Certificate />} />
      <Route path="*" element={<RootPage />} />
    </Route>
  )
);
export default router;
