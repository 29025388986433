import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material';

import Page from 'src/components/page';
import useDocument from 'src/hooks/useDocument';
import BackToSearch from 'src/components/backToSearch';
import HeaderContainer from 'src/components/headerContainer';
import PageHeader from 'src/components/pageHeader';
import PageContainer from 'src/components/pageContainer';
import ContentContainer from 'src/components/pageContentContainer';
import CardRow from 'src/components/cardRow';
import DocumentCategory from 'src/components/documentCategory';
import { getIconByCategory } from 'src/utils/category';
import Label from 'src/components/label';
import Text from 'src/components/text';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';
import Card from 'src/components/card';
import DownloadDocument from 'src/components/downloadDocument';

import BlockchainAddress from './components/BlockchainAddress';
import RecycledMaterials from './components/RecycledMaterials';
import {
  StyledAttachment,
  StyledCard,
  StyledCardRow,
  StyledCommentHeader
} from './styles';
import CertificateComponents from './components/CertificateComponents';

const Certificate = () => {
  const { productId } = useParams();
  const { data: product } = useDocument(productId as string, {
    enabled: Boolean(productId)
  });

  const theme = useTheme();
  const Icon =
    product && getIconByCategory(product.category.toLowerCase())?.Icon;

  return (
    <Page title="Battery Product Passport">
      <PageContainer>
        <BackToSearch />
        <HeaderContainer>
          <PageHeader>General Battery Certificate</PageHeader>
          <DocumentCategory document={product}>
            {Icon && (
              <Icon className="Active" color={theme.palette.primary.main} />
            )}
          </DocumentCategory>
        </HeaderContainer>
        <ContentContainer>
          <StyledCardRow>
            <StyledCard>
              <CardRow>
                <BlockchainAddress
                  blockchainAddress={product?.documentBlockchainAddress}
                />
                <RecycledMaterials
                  label="Overall % recycled materials"
                  value={product?.percentRecycled}
                />
              </CardRow>
              {product && (
                <StyledAttachment>
                  <Text>{`${product.title}.pdf`}</Text>
                  {product && <DownloadDocument product={product} />}
                </StyledAttachment>
              )}
            </StyledCard>
            <StyledCard>
              <CardRow wrap>
                <div>
                  <Label>Product Name</Label>
                  <Text>{product?.productName || DEFAULT_VALUE_TEXT}</Text>
                </div>
                <div>
                  <Label>Country of Origin</Label>
                  <Text>{product?.countryOfOrigin || DEFAULT_VALUE_TEXT}</Text>
                </div>
                <div>
                  <Label>Factory</Label>
                  <Text>{product?.factory || DEFAULT_VALUE_TEXT}</Text>
                </div>
                <div>
                  <Label>Product ID (Certificate UID)</Label>
                  <Text>{product?.productId || DEFAULT_VALUE_TEXT}</Text>
                </div>
                <div>
                  <Label>Batch No</Label>
                  <Text>{product?.batchNumber || DEFAULT_VALUE_TEXT}</Text>
                </div>
                <div>
                  <Label>Company</Label>
                  <Text>{product?.company || DEFAULT_VALUE_TEXT}</Text>
                </div>
              </CardRow>
            </StyledCard>
          </StyledCardRow>
          <div>
            <StyledCommentHeader>Additional Comments</StyledCommentHeader>
            <Card dense>
              <Text>{product?.additionalComments || 'No comments'}</Text>
            </Card>
          </div>
          {product?.components && (
            <CertificateComponents components={product.components} />
          )}
        </ContentContainer>
      </PageContainer>
    </Page>
  );
};

export default Certificate;
