import { StyledLink } from './styles';

type Props = {
  link: string;
  label: string;
};

const TermLink = ({ link, label }: Props) => (
  <StyledLink target="_blank" href={link}>
    {label}
  </StyledLink>
);

export default TermLink;
