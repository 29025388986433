import { styled, Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: theme.palette.custom.textColors[50],
  boxShadow: `0px 2px 4px 0px #D6DDEF`
}));

export const Details = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column'
}));

export const Skeleton = styled('div')(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  backgroundColor: theme.palette.custom.textColors[200]
}));

export const DetailsHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.textColors[700]
}));

DetailsHeader.defaultProps = {
  variant: 'caption1'
};

export const DetailsText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  whiteSpace: 'break-spaces',
  color: theme.palette.custom.textColors[900]
}));

DetailsText.defaultProps = {
  variant: 'body1'
};

export const StyledBlockchainText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  wordBreak: 'break-all',
  color: theme.palette.custom.neutral[900]
}));

StyledBlockchainText.defaultProps = {
  variant: 'caption2'
};

export const IconContainer = styled(`div`)(({ theme }) => ({
  backgroundColor: theme.palette.custom.primary[50],
  color: theme.palette.custom.primary[300],
  width: theme.spacing(6),
  height: theme.spacing(6),
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));
