import Label from 'src/components/label';
import Text from 'src/components/text';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';
import Progress from 'src/components/progress';

import { StyledProgressContainer } from './styles';

type Props = {
  label: string;
  value?: number;
};

const RecycledMaterials = ({ label, value }: Props) => (
  <div>
    <Label>{label}</Label>
    <StyledProgressContainer>
      <Text>
        {typeof value !== 'undefined' ? `${value}%` : DEFAULT_VALUE_TEXT}
      </Text>
      <Progress value={value} />
    </StyledProgressContainer>
  </div>
);

export default RecycledMaterials;
