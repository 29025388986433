import { CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { ReactComponent as BackgroundPart } from 'src/assets/background-part.svg';

import { BackgroundPartContainer, StyledBaseLayout } from './styles';

const BaseLayout = () => (
  <StyledBaseLayout>
    <CssBaseline />
    <Outlet />
    <BackgroundPartContainer>
      <BackgroundPart />
    </BackgroundPartContainer>
  </StyledBaseLayout>
);

export default BaseLayout;
