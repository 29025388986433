import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Document } from 'src/types/document';
import { queryKeys } from 'src/constants/queryKeys';
import getDocumentHistory from 'src/api/getDocumentHistory';

const useRelatedDocuments = (
  productId: string,
  publisherId: string,
  options?: UseQueryOptions<Document[], AxiosError>
) =>
  useQuery<Document[], AxiosError>(
    [queryKeys.getRelatedDocuments, { productId, publisherId }],
    () => getDocumentHistory(productId, publisherId),
    options
  );

export default useRelatedDocuments;
