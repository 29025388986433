import { styled } from '@mui/material';

export const StyledPropertiesLayout = styled('div')(({ theme }) => ({
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  display: 'flex',
  '> div': {
    flex: 1
  }
}));
