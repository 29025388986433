import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { GetRelatedCertificatesResponse } from 'src/types/document';
import { queryKeys } from 'src/constants/queryKeys';
import getRelatedCertificates from 'src/api/getRelatedCertificates';

const useRelatedCertificates = (
  productId: string,
  options?: UseQueryOptions<GetRelatedCertificatesResponse, AxiosError>
) =>
  useQuery<GetRelatedCertificatesResponse, AxiosError>(
    [queryKeys.getRelatedCertificates, { productId }],
    () => getRelatedCertificates(productId),
    options
  );

export default useRelatedCertificates;
