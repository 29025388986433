import Card from 'src/components/card';
import Label from 'src/components/label';
import Text from 'src/components/text';
import { Document } from 'src/types/document';
import CardRow from 'src/components/cardRow';
import Skeleton from 'src/components/skeleton';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';

type Props = {
  product?: Document;
};

const ProductDetailsCard = ({ product }: Props) => {
  if (!product) {
    return <Skeleton height="270px" />;
  }

  return (
    <Card>
      <div>
        <Label>Full name</Label>
        <Text color="primary">{product.title}</Text>
      </div>
      <CardRow>
        <div>
          <Label>Chemistry Market Name</Label>
          <Text>{product.chemistryMarketName}</Text>
        </div>
        <div>
          <Label>Production Date</Label>
          <Text>
            {product.productionDate
              ? new Intl.DateTimeFormat(navigator.language).format(
                  Date.parse(product.productionDate)
                )
              : DEFAULT_VALUE_TEXT}
          </Text>
        </div>
        <div>
          <Label>Sourced</Label>
          <Text>{product.sourced}</Text>
        </div>
        <div>
          <Label>Chemical Name or Material</Label>
          <Text>{product.chemicalNameorMaterial}</Text>
        </div>
      </CardRow>
      <div>
        <Label>Morecular Formula</Label>
        <Text>{product.molecularFormula}</Text>
      </div>
    </Card>
  );
};

export default ProductDetailsCard;
