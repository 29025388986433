import BatteryProperty from 'src/components/batteryProperty';
import Skeleton from 'src/components/skeleton';
import { DocumentProperties } from 'src/types/document';

import { StyledChemicalCompositionGrid } from './styles';

type Props = {
  composition?: DocumentProperties[];
  labelMap?: Record<string, string>;
};

const CompositionGrid = ({ composition, labelMap = {} }: Props) => {
  if (!composition) {
    return <Skeleton height="76px" />;
  }

  return (
    <StyledChemicalCompositionGrid>
      {composition
        .filter(({ value }) => value)
        .map(({ label, value }, index) => (
          <BatteryProperty
            label={labelMap[label] || label}
            value={value}
            key={`prop_${index}`}
          />
        ))}
    </StyledChemicalCompositionGrid>
  );
};

export default CompositionGrid;
