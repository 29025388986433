import Text from 'src/components/text';
import Card from 'src/components/card';
import { Document } from 'src/types/document';
import Skeleton from 'src/components/skeleton';
import Label from 'src/components/label';
import { getByLabel } from 'src/utils/documentProperties';
import Map, { MapContainer } from 'src/components/map';
import { Pin } from 'src/types/map';
import { extractCoordinatesFromProduct } from 'src/components/map/utils';

import { CardContent } from './styles';

type Props = {
  title: string;
  product?: Document;
};

const ProducersMap = ({ product, title }: Props) => {
  if (!product) {
    return (
      <MapContainer>
        <Text variant="h5" dense>
          {title}
        </Text>
        <Skeleton height="700px" />
      </MapContainer>
    );
  }

  const pins: Pin[] = [];

  if (product.producer) {
    const coordinates = extractCoordinatesFromProduct(product.producer);

    if (coordinates) {
      pins.push({
        coordinates,
        iconPath: '/pin-orange.svg'
      });
    }
  }

  return (
    <MapContainer>
      <Text variant="h5" dense>
        {title}
      </Text>
      <Map
        pins={pins}
        card={
          <Card shadow dense style={{ opacity: 0.85 }}>
            <CardContent>
              <div>
                <Text hasBorder color="primary">
                  Company
                </Text>

                <div>
                  <Label>Waste company</Label>
                  <Text>{getByLabel(product.producer, 'company')}</Text>
                </div>

                <div>
                  <Label>Address</Label>
                  <Text>
                    {getByLabel(product.producer, 'factoryLocation', '-')}
                  </Text>
                </div>

                <div>
                  <Label>Geo location</Label>
                  <Text>
                    {getByLabel(product.producer, 'coordinates', '-')}
                  </Text>
                </div>
              </div>
            </CardContent>
          </Card>
        }
      />
    </MapContainer>
  );
};

export default ProducersMap;
