import axios, { AxiosRequestConfig } from 'axios';

import { mockApi } from './apiMock';

const getApiClient = (baseUrl: string, config: AxiosRequestConfig = {}) => {
  const instance = axios.create({
    baseURL: baseUrl,
    ...config
  });

  if (process.env.REACT_APP_MOCKED_ENDPOINTS) {
    mockApi(instance);
  }

  return instance;
};

export default getApiClient;
