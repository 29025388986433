import { PropsWithChildren } from 'react';

import { StyledSuccessCard, CardWithStatusContainer } from './styles';

const SuccessCard = ({ children }: PropsWithChildren) => (
  <StyledSuccessCard>
    <CardWithStatusContainer>{children}</CardWithStatusContainer>
  </StyledSuccessCard>
);

export default SuccessCard;
