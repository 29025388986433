export const formatNumber = (maybeNumber?: string) => {
  if (isNaN(Number(maybeNumber))) {
    return maybeNumber;
  }
  const number = Number(maybeNumber);

  return new Intl.NumberFormat(navigator.language, {
    maximumSignificantDigits: 3
  }).format(number);
};
