import { useParams } from 'react-router-dom';

import Page from 'src/components/page';
import useDocument from 'src/hooks/useDocument';
import BackToSearch from 'src/components/backToSearch';
import HeaderContainer from 'src/components/headerContainer';
import PageHeader from 'src/components/pageHeader';
import PageContainer from 'src/components/pageContainer';
import ContentContainer from 'src/components/pageContentContainer';
import CardContainer from 'src/components/cardContainer';
import Text from 'src/components/text';
import DetailsCardFromLabels from 'src/components/detailsCardFromLabels';
import Documents from 'src/components/documents/DocumentCertificates';
import CompositionRow from 'src/components/compositionRow';
import { Column } from 'src/components/flex';
import DocumentCategory from 'src/components/documentCategory';

import Map from './components/map';
import ProductCard from './components/productCard';
import DetailsCard from './components/detailsCard';

const BatteryRepurpose = () => {
  const { productId } = useParams();
  const { data: product } = useDocument(productId as string, {
    enabled: Boolean(productId)
  });

  return (
    <Page title="Battery Product Passport">
      <PageContainer>
        <BackToSearch />
        <HeaderContainer>
          <PageHeader>Battery Pack Repurpose Certificate</PageHeader>
          <DocumentCategory document={product} />
        </HeaderContainer>
        <ContentContainer>
          <div>
            <CardContainer>
              <ProductCard product={product} />
              <DetailsCard product={product} />
            </CardContainer>
          </div>
          <CardContainer>
            <div style={{ flex: 1 }}>
              <Text variant="h5" hasBorder>
                Repurpose
              </Text>
              <DetailsCardFromLabels
                emptyBackground
                product={product}
                labels={[
                  'Repurpose date',
                  'Technical Review date',
                  'Remanufacturing type'
                ]}
                dateLabels={['Repurpose date', 'Technical Review date']}
              />
            </div>
            <Documents
              document={product}
              relatedFields={['batteryPackSerialNumber']}
            />
          </CardContainer>
          <div>
            <Text variant="h5" hasBorder>
              Specification
            </Text>
            <Column>
              <Text color="label">General info</Text>
              <CompositionRow
                composition={product?.details}
                labels={[
                  'Weight',
                  'Gravimetric energy density',
                  'Cells setup',
                  'Total Capacity',
                  'Usable Capacity'
                ]}
              />
            </Column>
          </div>
          <Map product={product} />
        </ContentContainer>
      </PageContainer>
    </Page>
  );
};

export default BatteryRepurpose;
