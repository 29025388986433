import PoweredBy from 'src/components/poweredBy';
import TermLink from 'src/components/termLink';

import { StyledContainer, StyledRegulationsLinks } from './styles';

const Footer = () => (
  <StyledContainer>
    <PoweredBy />
    <StyledRegulationsLinks>
      <TermLink
        link="https://files.billongroup.com/uploads/Privacy_Policy_62ba401eae.pdf"
        label="Privacy Policy"
      />
      <TermLink
        link="https://files.billongroup.com/uploads/ENG_REGULAMIN_TDM_BILLON_2023_27b8e240a5.pdf"
        label="Terms and Conditions"
      />
    </StyledRegulationsLinks>
  </StyledContainer>
);

export default Footer;
