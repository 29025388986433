import { styled } from '@mui/material';

import { resolveScore } from 'src/utils/score';

export const StyledCardTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.custom.primary[100]}`,
  paddingBottom: theme.spacing(0.8),
  paddingRight: theme.spacing(0.8),
  svg: {
    color: theme.palette.custom.neutral[300]
  }
}));

export const ScoreIndicator = styled('div', {
  shouldForwardProp: propName =>
    !['score', 'value', 'maxScore'].includes(propName as string)
})<{ score: number; value: number; maxScore: number }>(
  ({ theme, score, value, maxScore }) => ({
    display: 'inline-block',
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
    backgroundColor: resolveScore(theme.palette, 0, maxScore),
    div: {
      backgroundColor: resolveScore(theme.palette, score, maxScore),
      borderRadius: theme.spacing(0.5),
      height: '100%',
      width: value > 0 && value < 1 ? `${value * 100}%` : '100%',
      borderTopRightRadius: value < 0.85 && value > 0 ? 0 : theme.spacing(0.5),
      borderBottomRightRadius:
        value < 0.85 && value > 0 ? 0 : theme.spacing(0.5)
    }
  })
);

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '2px',
  '> span': {
    marginRight: theme.spacing(1)
  }
}));
