import { useParams } from 'react-router-dom';

import Page from 'src/components/page';
import useDocument from 'src/hooks/useDocument';
import BackToSearch from 'src/components/backToSearch';
import HeaderContainer from 'src/components/headerContainer';
import PageHeader from 'src/components/pageHeader';
import PageContainer from 'src/components/pageContainer';
import ContentContainer from 'src/components/pageContentContainer';
import CardContainer from 'src/components/cardContainer';
import Text from 'src/components/text';
import Properties, { PropertiesLayout } from 'src/components/properties';
import Card from 'src/components/card';
import CompositionRow from 'src/components/compositionRow';
import { camelize, getByLabel } from 'src/utils/documentProperties';
import { Column } from 'src/components/flex';
import CardRow from 'src/components/cardRow';
import Label from 'src/components/label';
import { parseDate } from 'src/utils/date';
import Documents from 'src/components/documents/DocumentCertificates';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';
import DocumentCategory from 'src/components/documentCategory';

import ProductCard from './components/productCard';
import ReviewStatus from './components/reviewStatus';
import DetailsCard from './components/detailsCard';

const BatteryTechReview = () => {
  const { productId } = useParams();
  const { data: product } = useDocument(productId as string, {
    enabled: Boolean(productId)
  });

  return (
    <Page title="Battery Product Passport">
      <PageContainer>
        <BackToSearch />
        <HeaderContainer>
          <PageHeader>Battery Pack Technical Review Certificate</PageHeader>
          <DocumentCategory document={product} />
        </HeaderContainer>
        <ContentContainer>
          <div>
            <CardContainer>
              <ProductCard product={product} />
              <DetailsCard product={product} />
            </CardContainer>
          </div>
          <CardContainer>
            <div style={{ flex: 1 }}>
              <Text variant="h5" hasBorder>
                Technical Review information
              </Text>
              <ReviewStatus product={product} />
            </div>
            <Documents
              document={product}
              relatedFields={['batteryPackSerialNumber']}
            />
          </CardContainer>
          <CardContainer>
            <Properties>
              <Text color="label">Data info</Text>
              <Card>
                <CardRow>
                  {[
                    'Sale date',
                    'Battery Production Date',
                    'Technical Review date'
                  ].map(label => (
                    <div key={label.replaceAll(' ', '_')}>
                      <Label>{label}</Label>
                      <Text>
                        {parseDate(
                          getByLabel(product?.details, camelize(label))
                        )}
                      </Text>
                    </div>
                  ))}
                </CardRow>
              </Card>
            </Properties>
            <div />
          </CardContainer>
          <div>
            <Text variant="h5" hasBorder>
              Specification
            </Text>
            <Column>
              <PropertiesLayout>
                <Properties>
                  <Text color="label">General info</Text>
                  <CompositionRow
                    composition={product?.details}
                    labels={[
                      'Weight',
                      'Gravimetric energy density',
                      'Gravimetric energy density:', // HACK for backend data
                      'Cooling',
                      'Storage Temp Range',
                      'Cells setup',
                      'Total Capacity',
                      'Usable Capacity'
                    ]}
                  />
                </Properties>
              </PropertiesLayout>
              <Properties>
                <Text color="label">Description</Text>
                <Card dense>
                  <Text>
                    {getByLabel(
                      product?.details,
                      'description',
                      DEFAULT_VALUE_TEXT
                    )}
                  </Text>
                </Card>
              </Properties>
            </Column>
          </div>
        </ContentContainer>
      </PageContainer>
    </Page>
  );
};

export default BatteryTechReview;
