import Text from 'src/components/text';
import Card from 'src/components/card';
import { Document } from 'src/types/document';
import Skeleton from 'src/components/skeleton';
import Label from 'src/components/label';
import { getByLabel } from 'src/utils/documentProperties';
import Map, { MapContainer } from 'src/components/map';
import { Pin } from 'src/types/map';
import { extractCoordinatesFromProduct } from 'src/components/map/utils';

import { CardContent } from './styles';

type Props = {
  title: string;
  product?: Document;
};

const ChemistryProducersMap = ({ product, title }: Props) => {
  if (!product) {
    return (
      <MapContainer>
        <Text variant="h5" dense>
          {title}
        </Text>
        <Skeleton height="700px" />
      </MapContainer>
    );
  }

  const pins: Pin[] = [];

  if (product.lithiumProducer) {
    const coordinates = extractCoordinatesFromProduct(product.lithiumProducer);

    if (coordinates) {
      pins.push({
        coordinates,
        iconPath: '/pin-blue.svg'
      });
    }
  }

  if (product.cobaltProducer) {
    const coordinates = extractCoordinatesFromProduct(product.cobaltProducer);

    if (coordinates) {
      pins.push({
        coordinates,
        iconPath: '/pin-orange.svg'
      });
    }
  }

  const hasSameProducers =
    getByLabel(product.cobaltProducer, 'company') ===
    getByLabel(product.lithiumProducer, 'company');

  return (
    <MapContainer>
      <Text variant="h5" dense>
        {title}
      </Text>
      <Map
        pins={pins}
        card={
          <Card shadow dense style={{ opacity: 0.85 }}>
            <CardContent>
              <div>
                <Text hasBorder color="secondary">
                  Cobalt Producer
                </Text>
                <div>
                  <Label>Company</Label>
                  <Text>{getByLabel(product.cobaltProducer, 'company')}</Text>
                </div>
                <div>
                  <Label>Company Headquarters</Label>
                  <Text>
                    {getByLabel(product.cobaltProducer, 'companyHeadquaters')}
                  </Text>
                </div>
                <div>
                  <Label>Mine</Label>
                  <Text>{getByLabel(product.cobaltProducer, 'mine', '-')}</Text>
                </div>
                <div>
                  <Label>Coordinates</Label>
                  <Text>
                    {getByLabel(product.cobaltProducer, 'coordinates')}
                  </Text>
                </div>
              </div>
              <div>
                <Text
                  hasBorder
                  color={hasSameProducers ? 'secondary' : 'primary'}
                >
                  Lithium Producer
                </Text>
                <div>
                  <Label>Company</Label>
                  <Text>{getByLabel(product.lithiumProducer, 'company')}</Text>
                </div>
                <div>
                  <Label>Company Headquaters</Label>
                  <Text>
                    {getByLabel(product.lithiumProducer, 'companyHeadquaters')}
                  </Text>
                </div>
                <div>
                  <Label>Mine</Label>
                  <Text>
                    {getByLabel(product.lithiumProducer, 'mine', '-')}
                  </Text>
                </div>
                <div>
                  <Label>Coordinates</Label>
                  <Text>
                    {getByLabel(product.lithiumProducer, 'coordinates')}
                  </Text>
                </div>
              </div>
            </CardContent>
          </Card>
        }
      />
    </MapContainer>
  );
};

export default ChemistryProducersMap;
