import { styled, Typography } from '@mui/material';

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.textColors[600],
  fontWeight: 600,
  marginBottom: '2px'
}));

StyledLabel.defaultProps = {
  variant: 'caption1'
};
