import Text from 'src/components/text';
import { getIconByCategory } from 'src/utils/category';

import { StyledCardTitle } from './styles';

type Props = {
  category?: string;
  label: string;
};

const ScoringLabel = ({ category, label }: Props) => {
  const Icon = category && getIconByCategory(category)?.Icon;

  return (
    <StyledCardTitle>
      {Icon && <Icon width="20" height="20" color="neutral.300" />}
      <Text color="label">{label}</Text>
    </StyledCardTitle>
  );
};

export default ScoringLabel;
