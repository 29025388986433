import Label from 'src/components/label';
import Scoring from 'src/components//scoring';
import { getByLabel } from 'src/utils/documentProperties';
import Card from 'src/components/card';
import Text from 'src/components/text';
import { Document } from 'src/types/document';
import ScoringLabel from 'src/components/scoring/ScoringLabel';

type Props = {
  product?: Document;
};

const BatteryPackProducerScore = ({ product }: Props) => {
  if (!product) {
    return null;
  }

  return (
    <Card shadow dense>
      <ScoringLabel label="Battery Pack Producer Score" category="factory" />
      <div>
        <Label>Company</Label>
        <Text>{getByLabel(product.producer, 'companyFullName')}</Text>
      </div>
      <div>
        <Label>Child Labor Scoring</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.producer, 'childLaborScoring(6Max,1Min)')}
        />
      </div>
      <div>
        <Label>Human Rights Scoring</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.producer, 'humanRightsScoring(6max,1Min)')}
        />
      </div>
      <div>
        <Label>ESG Scoring</Label>
        <Scoring
          maxScore={6}
          score={getByLabel(product.producer, 'esgScoring(6max,1Min)')}
        />
      </div>
    </Card>
  );
};
export default BatteryPackProducerScore;
