import { Components, Theme } from '@mui/material';

export const MuiAppBarComponentConfiguration: Components<Theme>['MuiAppBar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.custom.neutral[900],
      backgroundColor: 'unset',
      boxShadow: 'none',
      zIndex: 200,
      '& .MuiToolbar-root.MuiToolbar-regular': {
        minHeight: 'unset',
        margin: theme.spacing(4, 17.75)
      }
    })
  }
};
