import { Toolbar } from '@mui/material';

import BillonLogoLink from 'src/components/billonLogoLink';

import { StyledAppBar, StyledTypography } from './styles';

type Props = {
  title?: string;
};

const AppBar = ({ title }: Props) => (
  <StyledAppBar>
    <Toolbar disableGutters>
      <BillonLogoLink />
      {title && <StyledTypography>{title}</StyledTypography>}
    </Toolbar>
  </StyledAppBar>
);

export default AppBar;
