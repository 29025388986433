import { TypographyWithTheme } from './types';

const typography: TypographyWithTheme = theme => ({
  button: undefined,
  caption: undefined,
  display01: {
    fontFamily: 'Montserrat',
    fontSize: 66,
    lineHeight: 1.1,
    letterSpacing: '-0.05em',
    [theme.breakpoints.down('desktop')]: {
      fontSize: 46
    }
  },
  display02: {
    fontFamily: 'Montserrat',
    fontSize: 58,
    lineHeight: 1.2,
    letterSpacing: '-0.03em',
    [theme.breakpoints.down('desktop')]: {
      fontSize: 41
    }
  },
  display03: {
    fontFamily: 'Montserrat',
    fontSize: 52,
    lineHeight: 1.2,
    letterSpacing: '-0.02em',
    [theme.breakpoints.down('desktop')]: {
      fontSize: 36
    }
  },
  h1: {
    fontSize: 46,
    lineHeight: 1.2,
    letterSpacing: '-0.02em',
    [theme.breakpoints.down('tablet')]: {
      fontSize: 32
    },
    [theme.breakpoints.down('mobile')]: {
      fontSize: 26
    }
  },
  h2: {
    fontSize: 41,
    lineHeight: 1.3,
    letterSpacing: '-0.01em',
    [theme.breakpoints.down('desktop')]: {
      fontSize: 29
    },
    [theme.breakpoints.down('tablet')]: {
      fontSize: 26
    }
  },
  h3: {
    fontSize: 36,
    lineHeight: 1.3,
    letterSpacing: '-0.01em',
    [theme.breakpoints.down('desktop')]: {
      fontSize: 26
    }
  },
  h4: {
    fontSize: 29,
    lineHeight: 1.4,
    letterSpacing: '-0.01em',
    [theme.breakpoints.down('desktop')]: {
      fontSize: 23
    }
  },
  h5: {
    fontSize: 23,
    lineHeight: 1.4,
    letterSpacing: '-0.01em',
    [theme.breakpoints.down('desktop')]: {
      fontSize: 20
    }
  },
  h6s: {
    fontSize: 18,
    lineHeight: 1.4,
    display: 'block'
  },
  h6: {
    fontSize: 20,
    lineHeight: 1.4
  },
  subtitle1: {
    fontSize: 16,
    lineHeight: 1.5
  },
  subtitle2: {
    fontSize: 14,
    lineHeight: 1.4
  },
  body1: {
    fontSize: 16,
    lineHeight: 1.5
  },
  body2: {
    lineHeight: 1.4,
    fontSize: 14,
    letterSpacing: '0.01em'
  },
  caption1: {
    fontFamily: 'Montserrat',
    lineHeight: 1.4,
    fontSize: 13,
    letterSpacing: '0.01em',
    display: 'block'
  },
  caption2: {
    fontFamily: 'Montserrat',
    lineHeight: 1.4,
    fontSize: 11,
    letterSpacing: '0.01em'
  },
  overline: {
    lineHeight: 1.4,
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: '0.01em'
  },
  buttonLarge: {
    fontFamily: 'Montserrat',
    lineHeight: 1.5,
    textTransform: 'none',
    fontSize: 16
  },
  buttonMedium: {
    fontFamily: 'Montserrat',
    lineHeight: 1.4,
    fontSize: 14,
    textTransform: 'none',
    letterSpacing: '0.01em'
  },
  buttonSmall: {
    fontFamily: 'Montserrat',
    lineHeight: 1.4,
    fontSize: 13,
    textTransform: 'none',
    letterSpacing: '0.01em'
  }
});

export default typography;
