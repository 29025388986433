import BatteryProperty from 'src/components/batteryProperty';
import Skeleton from 'src/components/skeleton';
import { DocumentProperties } from 'src/types/document';
import { camelize, getByLabel } from 'src/utils/documentProperties';

import { StyledCompositionRow } from './styles';

type Props = {
  composition?: DocumentProperties[];
  displayEmpty?: boolean;
  labels?: string[];
};

const CompositionRow = ({ composition, displayEmpty, labels }: Props) => {
  if (!composition) {
    return <Skeleton height="76px" />;
  }

  if (!labels) {
    return (
      <StyledCompositionRow>
        {composition.map(({ label, value }, index) => (
          <BatteryProperty label={label} value={value} key={`row_${index}`} />
        ))}
      </StyledCompositionRow>
    );
  }

  return (
    <StyledCompositionRow>
      {labels.map((label, index) => {
        const value = getByLabel(composition, camelize(label));

        if (!displayEmpty && !value) {
          return null;
        }

        return (
          <BatteryProperty label={label} value={value} key={`row_${index}`} />
        );
      })}
    </StyledCompositionRow>
  );
};

export default CompositionRow;
