import Text from 'src/components/text';
import Card from 'src/components/card';
import Skeleton from 'src/components/skeleton';

type Props = {
  text?: string;
};

const ElectricalPerformance = ({ text }: Props) => {
  if (!text) {
    return <Skeleton height="60px" />;
  }
  return (
    <Card dense>
      <Text>{text}</Text>
    </Card>
  );
};

export default ElectricalPerformance;
