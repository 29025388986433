import { Components, Theme } from '@mui/material';

export const MuiInputAdornmentComponentConfiguration: Components<Theme>['MuiInputAdornment'] =
  {
    styleOverrides: {
      positionEnd: ({ theme }) => ({
        svg: {
          color: theme.palette.custom.neutral[700]
        }
      })
    }
  };
