import { styled } from '@mui/material';

import Text from 'src/components/text';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

export const StyledComponentTitle = styled(Text)(() => ({
  marginBottom: 0
}));

export const StyledSearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  h5: {
    padding: 0,
    flex: 1
  },
  form: {
    button: {
      padding: theme.spacing(1, 2)
    },
    display: 'flex',
    gap: theme.spacing(2),
    '.MuiInputBase-root': {
      paddingRight: 0
    },
    input: {
      height: 26,
      width: theme.spacing(40),
      padding: theme.spacing(1, 1, 1, '12px')
    }
  }
}));
