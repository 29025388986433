import { styled } from '@mui/material';

export const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.custom.primary[100]}`,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.custom.textColors[300],
  svg: {
    width: 44
  },
  '.Active': {
    backgroundColor: theme.palette.custom.primary[50],
    borderRadius: theme.spacing(1)
  }
}));
