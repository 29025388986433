import { styled } from '@mui/material';

export const CardRow = styled('div', {
  shouldForwardProp: prop =>
    !['wrap', 'center', 'dense'].includes(prop.toString())
})<{ wrap?: boolean; center?: boolean; dense?: boolean }>(
  ({ theme, wrap, center, dense }) => ({
    rowGap: theme.spacing(dense ? 1 : 2),
    display: 'flex',
    flexWrap: wrap ? 'wrap' : 'unset',
    justifyContent: center ? 'center' : 'unset',
    alignItems: center ? 'center' : 'unset',
    columnGap: theme.spacing(3)
  })
);
