import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

import routes from 'src/constants/routes';

import { StyledButton } from './styles';

const BackToSearch = () => {
  const navigate = useNavigate();

  return (
    <div>
      <StyledButton
        size="small"
        onClick={() => navigate(routes.index)}
        startIcon={<KeyboardArrowLeft />}
      >
        Back to search
      </StyledButton>
    </div>
  );
};

export default BackToSearch;
