import Text from 'src/components/text';
import RelatedDocuments from 'src/components/relatedDocuments';
import { Document } from 'src/types/document';
import CardContainer from 'src/components/cardContainer';

import { StyledContent } from './styles';
import DocumentCertificates from './DocumentCertificates';

type Props = {
  document?: Document;
  relatedFields: string[];
};

const Documents = ({ document, relatedFields }: Props) => (
  <CardContainer>
    <StyledContent>
      <Text variant="h5" hasBorder>
        Related documents
      </Text>
      {document && <RelatedDocuments product={document} />}
    </StyledContent>
    <DocumentCertificates document={document} relatedFields={relatedFields} />
  </CardContainer>
);

export default Documents;
