import { useParams } from 'react-router-dom';

import Page from 'src/components/page';
import useDocument from 'src/hooks/useDocument';
import BackToSearch from 'src/components/backToSearch';
import HeaderContainer from 'src/components/headerContainer';
import PageHeader from 'src/components/pageHeader';
import PageContainer from 'src/components/pageContainer';
import ContentContainer from 'src/components/pageContentContainer';
import CardContainer from 'src/components/cardContainer';
import Text from 'src/components/text';
import Properties, { PropertiesLayout } from 'src/components/properties';
import Card from 'src/components/card';
import CompositionRow from 'src/components/compositionRow';
import { getByLabel } from 'src/utils/documentProperties';
import { Column } from 'src/components/flex';
import { DEFAULT_VALUE_TEXT } from 'src/constants/text';
import DocumentCategory from 'src/components/documentCategory';
import RelatedDocuments from 'src/components/relatedDocuments';

import ProducersMap from './components/producersMap';
import ProductCard from './components/productCard';
import DetailsCard from './components/detailsCard';
import Scoring from './components/scoring/Product';
import Certificates from './components/certificates';

const BatteryPacks = () => {
  const { productId } = useParams();
  const { data: product } = useDocument(productId as string, {
    enabled: Boolean(productId)
  });

  return (
    <Page title="Battery Product Passport">
      <PageContainer>
        <BackToSearch />
        <HeaderContainer>
          <PageHeader>Battery Pack Certificate</PageHeader>
          <DocumentCategory document={product} />
        </HeaderContainer>
        <ContentContainer>
          <div>
            <CardContainer>
              <ProductCard product={product} />
              <DetailsCard product={product} />
            </CardContainer>
          </div>
          <div>
            <Text variant="h5" hasBorder>
              Related documents
            </Text>
            {product && <RelatedDocuments product={product} />}
          </div>
          <Certificates document={product} relatedField={product?.productId} />
          <Scoring product={product} />
          <div>
            <Text variant="h5" hasBorder>
              Specification
            </Text>
            <Column>
              <PropertiesLayout>
                <Properties>
                  <Text color="label">General info</Text>
                  <CompositionRow
                    composition={product?.details}
                    labels={[
                      'Weight',
                      'Gravimetric energy density',
                      'Gravimetric energy density:',
                      'Cooling',
                      'Cells Setup',
                      'Total Capacity',
                      'Usable Capacity',
                      'StorageTempRange'
                    ]}
                  />
                  <Properties>
                    <Text color="label">Description</Text>
                    <Card dense>
                      <Text>
                        {getByLabel(
                          product?.details,
                          'description',
                          DEFAULT_VALUE_TEXT
                        )}
                      </Text>
                    </Card>
                  </Properties>
                </Properties>
                <Properties>
                  <Text color="label">Relevant numbers</Text>
                  <CompositionRow
                    composition={product?.sizeCalculation}
                    displayEmpty
                    labels={[
                      'Orders',
                      'Manuals',
                      'Car manual',
                      'Description of Recycling'
                    ]}
                  />
                  <Properties>
                    <Text color="label">Warranty Conditions</Text>
                    <Card dense>
                      <Text>
                        {getByLabel(
                          product?.details,
                          'warrantyConditions',
                          DEFAULT_VALUE_TEXT
                        )}
                      </Text>
                    </Card>
                  </Properties>
                </Properties>
              </PropertiesLayout>
            </Column>
          </div>
          <div>
            <ProducersMap title="Battery Pack producers" product={product} />
          </div>
        </ContentContainer>
      </PageContainer>
    </Page>
  );
};

export default BatteryPacks;
