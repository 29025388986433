import { styled } from '@mui/material';

import Text from 'src/components/text';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: theme.spacing(3),
  a: {
    button: {
      padding: theme.spacing(0.5, 1.5)
    },
    textDecoration: 'none',
    '> div': {
      display: 'flex'
    },
    span: {
      whiteSpace: 'nowrap'
    }
  }
}));

export const StyledCardTitleDescription = styled(Text)(({ theme }) => ({
  color: theme.palette.custom.textColors[600]
}));

export const StyledCardTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  svg: {
    color: theme.palette.custom.neutral[300],
    minWidth: 20
  },

  borderBottom: `1px solid ${theme.palette.custom.primary[100]}`,
  a: {
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    textDecoration: 'none',
    '> div': {
      display: 'flex',
      gap: theme.spacing(1)
    },
    span: {
      whiteSpace: 'nowrap'
    },
    button: {
      paddingTop: 0,
      paddingBottom: 0
    }
  }
}));

export const StyledIconContainer = styled('div')(({ theme }) => ({
  height: 20,
  svg: {
    marginTop: theme.spacing(0.5)
  }
}));
