import { LinearProgressProps } from '@mui/material';

import { StyledLinearProgress } from './styles';

const Progress = (props: LinearProgressProps) => {
  let color: LinearProgressProps['color'] = 'warning';

  if (typeof props.value !== 'undefined' && !isNaN(props.value)) {
    const value = Number(props.value);

    if (value <= 30) {
      color = 'error';
    } else if (value > 75) {
      color = 'success';
    }
  }
  return (
    <StyledLinearProgress variant="determinate" color={color} {...props} />
  );
};

export default Progress;
